import {
  Component,
  OnInit,
  ViewContainerRef,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { MenuItemsService } from '../menu-items.service';

@Component({
  host: { style: 'display: none' },
  selector: 'integrity-filer-menu-items',
  templateUrl: './filer-menu-items.component.html',
  styleUrls: ['./filer-menu-items.component.scss'],
  providers: [MenuItemsService],
})
export class FilerMenuItemsComponent implements OnInit {
  // This template/content stuff is used to move the content outside of the
  // <integrity-filer-menu-items> element in the html. This is needed
  // because the css is set up to expect the menu ul/li/a elements directly
  // inside each other.
  // Taken from: https://github.com/angular/angular/issues/18877
  @ViewChild('content', { static: true }) content: TemplateRef<{}>;
  constructor(
    private readonly viewContainer: ViewContainerRef,
    public menuItemsService: MenuItemsService,
  ) {}

  public window: Window = window;

  ngOnInit(): void {
    this.viewContainer.createEmbeddedView(this.content);
    this.menuItemsService.init();
  }
}
