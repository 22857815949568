import { isBreakStatement } from 'typescript';
import { FilingTypeKey } from './filing-type-key.enum';
import { RoleKey } from './role.model';

export abstract class Staff {
  public static getPrimaryRoleProperty(
    role: RoleKey,
    filingType: FilingTypeKey | undefined = undefined
  ): string {
    switch (role) {
      case RoleKey.AgencyAdministrator:
        return 'administrator';
      case RoleKey.AgencyDAEO:
        return 'daeo';
      case RoleKey.AgencyRecordsManager:
        return 'agencyRecordsManager';        
      case RoleKey.CertifyingOfficial:
        switch (filingType) {
          case FilingTypeKey.General:
            return 'general278certifyingOfficial';
          case FilingTypeKey.Confirmed:
            return 'confirmed278certifyingOfficial';
        }
        break;
      case RoleKey.EthicsOfficial:
        switch (filingType) {
          case FilingTypeKey.General:
            return 'general278ethicsOfficial';
          case FilingTypeKey.Confirmed:
            return 'confirmed278ethicsOfficial';
        }
        break;
      case RoleKey.Screener:
        switch (filingType) {
          case FilingTypeKey.General:
            return 'general278reviewer';
          case FilingTypeKey.Confirmed:
            return 'confirmed278reviewer';
        }
        break;
      case RoleKey.OGEAdministrator:
        return 'ogeAdministrator';
      case RoleKey.OGECertifyingOfficial:
        return 'ogeCertifyingOfficial';
      case RoleKey.OGEDirector:
        return 'ogeDirector';
      case RoleKey.OGENomineeCertifyingOfficial:
        return 'ogeNomineeCertifyingOfficial';
      case RoleKey.OGENomineeProgramManager:
        return 'ogeNomineeProgramManager';
      case RoleKey.OGENomineeRecordsManager:
        return 'ogeNomineeRecordsManager';
      case RoleKey.OGENomineeReviewer:
        return 'ogeNomineeReviewer';
      case RoleKey.OGERecordsManager:
        return 'ogeRecordsManager';
      case RoleKey.OGEReviewer:
        return 'ogeReviewer';
      case RoleKey.NomineeReviewer:
        return 'nomineeReviewer';
      case RoleKey.PointOfContact:
        return 'contact';
      case RoleKey.PPOLead:
        return 'ppoLead';
      case RoleKey.PPOReviewer:
        return 'ppoReviewer';
      case RoleKey.PTTLead:
        return 'pttLead';
      case RoleKey.PTTReviewer:
        return 'pttReviewer';
      case RoleKey.RecordsManager:
        return 'recordsManager';
      case RoleKey.Router:
        return 'router';
      case RoleKey.Screener:
        switch (filingType) {
          case FilingTypeKey.General:
            return 'general278reviewer';
          case FilingTypeKey.Confirmed:
            return 'confirmed278reviewer';
        }
        break;
      case RoleKey.Supervisor:
        switch (filingType) {
          case FilingTypeKey.General:
            return 'general278supervisor';
        }
        break;
      case RoleKey.SystemAdministrator:
        return 'helpDesk';
      case RoleKey.SystemAssistant:
        return 'helpDeskAssistant';
      case RoleKey.SystemSuperuser:
        return 'superuser';
      case RoleKey.WHCOLead:
        return 'whcoLead';
      case RoleKey.WHCOReviewer:
        return 'whcoReviewer';
    }

    throw new Error(
      `Primary staff property not found for role key "${role}", filing type "${filingType}"`
    );
  }

  public static getAlternateRoleProperty(
    role: RoleKey,
    filingType: FilingTypeKey | undefined = undefined
  ): string {
    switch (role) {
      case RoleKey.AgencyDAEO:
        return 'alternateDaeos';
      case RoleKey.AgencyAdministrator:
        return 'alternateAdministrators';
      case RoleKey.AgencyRecordsManager:
        return 'alternateAgencyRecordsManagers'
      case RoleKey.CertifyingOfficial:
        switch (filingType) {
          case FilingTypeKey.General:
            return 'general278alternateCertifyingOfficials';
          case FilingTypeKey.Confirmed:
            return 'confirmed278alternateCertifyingOfficials';
        }
        break;
      case RoleKey.EthicsOfficial:
        switch (filingType) {
          case FilingTypeKey.General:
            return 'general278alternateEthicsOfficials';
          case FilingTypeKey.Confirmed:
            return 'confirmed278alternateEthicsOfficials';
        }
        break;
      case RoleKey.Screener:
        switch (filingType) {
          case FilingTypeKey.General:
            return 'general278alternateReviewers';
          case FilingTypeKey.Confirmed:
            return 'confirmed278alternateReviewers';
        }
        break;
      case RoleKey.Filer:
        return 'filers';
      case RoleKey.OGEAdministrator:
        return 'alternateOgeAdministrators';
      case RoleKey.OGECertifyingOfficial:
        return 'alternateOgeCertifyingOfficials';
      case RoleKey.OGENomineeCertifyingOfficial:
        return 'alternateOgeNomineeCertifyingOfficials';
      case RoleKey.OGENomineeProgramManager:
        return 'alternateOgeNomineeProgramManagers';
      case RoleKey.OGENomineeRecordsManager:
        return 'alternateOgeNomineeRecordsManagers';
      case RoleKey.OGENomineeReviewer:
        return 'alternateOgeNomineeReviewers';
      case RoleKey.OGERecordsManager:
        return 'alternateOgeRecordsManagers';
      case RoleKey.OGEReviewer:
        return 'alternateOgeReviewers';
      case RoleKey.NomineeReviewer:
        return 'alternateNomineeReviewers';
      case RoleKey.PointOfContact:
        return 'alternateContacts';
      case RoleKey.PPOReviewer:
        return 'alternatePpoReviewers';
      case RoleKey.PTTReviewer:
        return 'alternatePttReviewers';
      case RoleKey.RecordsManager:
        return 'alternateRecordsManagers';
      case RoleKey.Router:
        return 'alternateRouters';
      case RoleKey.Screener:
        switch (filingType) {
          case FilingTypeKey.General:
            return 'general278alternateReviewers';
          case FilingTypeKey.Confirmed:
            return 'confirmed278alternateReviewers';
        }
        break;
      case RoleKey.Supervisor:
        switch (filingType) {
          case FilingTypeKey.General:
            return 'general278alternateSupervisors';
        }
        break;
      case RoleKey.SystemAdministrator:
        return 'alternateHelpDesks';
      case RoleKey.SystemAssistant:
        return 'alternateHelpDeskAssistants';
      case RoleKey.SystemSuperuser:
        return 'alternateSuperusers';
      case RoleKey.WHCOReviewer:
        return 'alternateWhcoReviewers';
    }

    throw new Error(
      `Alternates staff property not found for role key "${role}", filing type "${filingType}"`
    );
  }

  public setPrimary(
    role: RoleKey,
    userId: string,
    filingType: FilingTypeKey | undefined = undefined
  ) {
    const prop: string = Staff.getPrimaryRoleProperty(role, filingType);

    this[prop] = userId;
  }

  public addAlternate(
    role: RoleKey,
    userId: string,
    filingType: FilingTypeKey | undefined = undefined
  ): boolean {
    const prop: string = Staff.getAlternateRoleProperty(role, filingType);
    let alternates = this[prop];

    if (alternates && !Array.isArray(alternates)) {
      throw new Error(`Staff property '${prop}' is not an array`);
    } else if (!alternates) {
      alternates = [];
      this[prop] = alternates;
    }

    if (!alternates.includes(userId)) {
      this[prop].push(userId);
      return true;
    }

    return false;
  }

  public getPrimary(
    role: RoleKey,
    filingType: FilingTypeKey | undefined = undefined
  ): string {
    const prop: string = Staff.getPrimaryRoleProperty(role, filingType);

    return this[prop];
  }

  public getAlternates(
    role: RoleKey,
    filingType: FilingTypeKey | undefined = undefined
  ): string[] {
    const prop: string = Staff.getAlternateRoleProperty(role, filingType);

    return this[prop] ?? [];
  }

  public hasAlternate(
    role: RoleKey,
    userId: string,
    filingType: FilingTypeKey | undefined = undefined
  ): string[] {
    const prop: string = Staff.getAlternateRoleProperty(role, filingType);

    return (this[prop] ?? []).includes(userId);
  }

  public removePrimary(
    role: RoleKey,
    filingType: FilingTypeKey | undefined = undefined
  ) {
    const prop: string = Staff.getPrimaryRoleProperty(role, filingType);

    delete this[prop];
  }

  public removeAlternate(
    role: RoleKey,
    userId: string,
    filingType: FilingTypeKey | undefined = undefined
  ) {
    const prop: string = Staff.getAlternateRoleProperty(role, filingType);
    const alternates = this[prop];

    if (alternates && Array.isArray(alternates)) {
      const index = alternates.indexOf(userId);
      if (index > -1) {
        alternates.splice(index, 1);
      }
    }
  }

  public doesUserHoldRole(
    role: RoleKey,
    userId: string,
    filingType: FilingTypeKey | undefined = undefined
  ): boolean {
    const primaryProperty = Staff.getPrimaryRoleProperty(role, filingType);
    let alternateProperty: string | undefined;
    try {
      alternateProperty = Staff.getAlternateRoleProperty(role, filingType);
    } catch (e) {}

    return (
      (primaryProperty &&
        this[primaryProperty] &&
        this[primaryProperty] == userId) ||
      (alternateProperty &&
        Array.isArray(this[alternateProperty]) &&
        this[alternateProperty].includes(userId))
    );
  }

  removeAllWorkflowStaff() {
    Object.keys(this).forEach((key) => {
      if (key.match(/^(general|confirmed)278.+/)) {
        this[key] = undefined;
      }
    });
  }

  /**
   * Remove all staff for the given filing type: general278 or confirmed278
   * EFEDS-7085
   */
  removeStaffForFilingType(filingTypeKey: string): void {
    Object.keys(this).forEach((key) => {
      const re = new RegExp(filingTypeKey);
      if (key.match(re)) {
        this[key] = undefined;
      }
    });
  }
}

export class RegularStaff extends Staff {
  daeo?: string;
  alternateDaeos?: Array<string>;
  administrator?: string;
  alternateAdministrators?: Array<string>;
  contact?: string;
  alternateContacts?: Array<string>;
  nomineeReviewer?: string;
  alternateNomineeReviewers?: Array<string>;
  recordsManager?: string;
  alternateRecordsManagers?: Array<string>;
  general278supervisor?: string;
  general278alternateSupervisors?: string;
  general278reviewer?: string;
  general278alternateReviewers?: Array<string>;
  general278ethicsOfficial?: string;
  general278alternateEthicsOfficials?: Array<string>;
  general278certifyingOfficial?: string;
  general278alternateCertifyingOfficials?: Array<string>;
  router?: string;
  alternateRouters?: Array<string>;
  confirmed278reviewer?: string;
  confirmed278alternateReviewers?: Array<string>;
  confirmed278ethicsOfficial?: string;
  confirmed278alternateEthicsOfficials?: Array<string>;
  confirmed278certifyingOfficial?: string;
  confirmed278alternateCertifyingOfficials?: Array<string>;
  filers?: Array<string>;
}

export class OGEOversightStaff extends Staff {
  ogeDirector?: string;
  ogeAdministrator?: string;
  alternateOgeAdministrators?: Array<string>;
  ogeRecordsManager?: string;
  alternateOgeRecordsManagers?: Array<string>;
  ogeNomineeRecordsManager?: string;
  alternateOgeNomineeRecordsManagers?: string;
  superuser?: string;
  alternateSuperusers?: Array<string>;
  helpDesk?: string;
  alternateHelpDesks?: Array<string>;
  helpDeskAssistant?: string;
  alternateHelpDeskAssistants?: Array<string>;
  ogeReviewer?: string;
  alternateOgeReviewers?: Array<string>;
  ogeCertifyingOfficial?: string;
  alternateOgeCertifyingOfficials?: Array<string>;
  router?: string;
  alternateRouters?: Array<string>;
  ogeNomineeReviewer?: string;
  alternateOgeNomineeReviewers?: Array<string>;
  ogeNomineeProgramManager?: string;
  alternateOgeNomineeProgramManagers?: Array<string>;
  ogeNomineeCertifyingOfficial?: string;
  alternateOgeNomineeCertifyingOfficials?: Array<string>;
}

export class PPOWHCOStaff extends Staff {
  pttLead?: string;
  ppoLead?: string;
  whcoLead?: string;
  administrator?: string;
  alternateAdministrators?: Array<string>;
  pttReviewer?: string;
  alternatePttReviewers?: Array<string>;
  ppoReviewer?: string;
  alternatePpoReviewers?: Array<string>;
  whcoReviewer?: string;
  alternateWhcoReviewers?: Array<string>;
}
