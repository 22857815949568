import { Injectable } from '@angular/core';
import { defer, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { QueryService } from './query.service';
import { SessionService } from './session.service';
import { ValueOption } from '../models/value-option.model';
import { ValueOptions } from '../models/value-options.model';
import _ from 'lodash-es';
import { CacheFactory, Cache } from 'cachefactory';
import {ConfigDataService} from './config-data.service';

interface Data {
  results: Results;
}

interface Results {
  array: ValueOptions;
  map: any;
}

const cacheFactory = new CacheFactory();

/**
 *  ~ ValuesFactory.js
 * Includes functions for getting month and year option lists which was in GridService.
 */
@Injectable({
  providedIn: 'root',
})
export class ValuesService {
  searchServicePath = ConfigService.MAX_PAAS_VALUES_SEARCH_PERSISTENCE_URL;
  servicePath = ConfigService.MAX_PAAS_VALUES_PERSISTENCE_URL;
  cache: Cache;

  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    private queryService: QueryService,
    private configDataService: ConfigDataService,
  ) {
    if (!cacheFactory.exists('values')) {
      this.cache = cacheFactory.createCache('values');
    } else {
      this.cache = cacheFactory.get('values');
    }
  }

  getOptionValues(optionValuesSetName: string): Observable<any> {
    return defer(() => {
      // return cached options, if possible
      const cached = this.cache.get(optionValuesSetName);
      if (cached) return of(cached);

      const rulesVersion = this.getRulesVersion(optionValuesSetName);
      switch (optionValuesSetName) {
        case 'Filing Types':
          return this.getFilingTypeOptions();

        case 'Filing Type':
          return of(this.getFilingTypes());

        case 'Spouse':
          return of(this.getSpouse());

        case 'Filer Status':
          return of(this.getFilerStatus());

        case 'Export Filing Status':
          return of(this.getExportFilingStatus());

        case 'Export Item':
          return of(this.getExportItems());

        case 'Investment Fund':
          return of(this.getEIFOptionList());

        case 'EIF':
          return of(this.getEIFGridOptionList());

        case 'Income Type':
          return of(this.getIncomeTypeOptionList());

        case 'Income Amount':
          return of(this.getIncomeAmountOptionList());

        case 'Month':
          return of(this.getMonthOptionList());

        case 'Year':
          return of(this.getYearOptionList());

        case 'YesNo':
          return of(this.getYesNoList());

        case 'Yes or No or Do Not Know':
          return of(this.getYesNoDoNotKnowList());

        case 'Yes or No - Not Ascertainable':
          return of(this.getYesNoNotAscertainable());

        case 'DivIntCapRentOther':
          return of(this.getDivIntCapRentOther());

        case 'States':
          return of(this.getStates());
          
        // OGE-7798 Do not use the value options spreadsheet country list - use only the max list
        case 'Country':
          return of(this.getCountryOptions());
       
        case 'Countries':
          return of(this.getCountries());

        case 'DCP Participation':
          return of(this.getDCPParticipation());

        case 'DCP Contributions':
          return of(this.getDCPContributions());

        case 'Organization Types':
          return of(this.getOrganizationTypes());

        case 'UniversityCollege Position Type':
        case 'university_college':
          return of(this.getUniversityCollegePositionTypes());

        case 'NonProfit Position Type':
          return of(this.getNonProfitPositionTypes());

        case 'Corporation Position Type':
          return of(this.getCorporationPositionTypes());

        case 'Trust Position Type':
          return of(this.getTrustPositionTypes());

        case 'SoloLegalPractice Position Type':
          return of(this.getSoloLegalPracticePositionTypes());

        case 'LawFirm Position Type':
          return of(this.getLawFirmPositionTypes());

        case 'Other Position Type':
          return of(this.getOtherPositionTypes());

        case 'Document Types':
          return of(this.getDocumentTypes());

        case 'Retention Periods':
          return of(this.getRetentionPeriodOptions());

        case 'Public Availability':
          return of(this.getPublicAvailability());

        case 'Post or Do Not Post':
          return of(this.getPostOrDoNotPost());

        case 'Group Name':
          return this.getGroupNameOptions();

        case 'Item Owner':
          return of(this.getItemOwnerOptions());

        case 'Item Owner Grid':
          return of(this.getItemOwnerGridOptions());

        case 'Type of Transaction':
          return of(this.getTypeOfTransactionOptions());

        case 'Type of Liability':
          return of(this.getTypeOfLiabilityOptions());

        case 'Hours':
          return of(this.getHours());

        case 'Minutes':
          return of(this.getMinutes());

        case 'Meridiem':
          return of(this.getMeridiem());

        case 'DCP Contributions':
          return of(this.getDCPContributions());

        case 'Filer Category Nominee':
        case 'Filer Category v201902 Nominee':
          return this.getFilerCategoriesNominee(optionValuesSetName);

        case 'Filer Category All':
          return this.getFilerCategoryAll();

        case 'Report Item':
          return this.getReportItems();
          
        case 'Report Status':
          return this.getReportStatuses();

        case 'Rules Versions':
          return this.getRulesVersions();
          
        case 'Appointment Types':
          return this.getFilerAppointmentTypes();

        default:
          return this.getValueOptionsBySetName(optionValuesSetName);
      }
    });
  }

  getValueOptionsBySetName(optionValuesSetName: string): Observable<any> {
    const serviceEndpoint = this.servicePath + optionValuesSetName + '.json';

    return this.http
      .get<any>(serviceEndpoint, {
        headers: this.sessionService.getAuthHeader(),
      })
      .pipe(
        map((data) => {
          const options = ValueOptions.create(data);
          this.cache.put(optionValuesSetName, options);
          if (data.status !== 0) {
            console.log(
              'Unable to retrieve value options for ' + optionValuesSetName
            );
          }
          return options;
        })
      );
  }

  public getMonthOptionList(): ValueOptions {
    const options = new ValueOptions();
    options.options = [
      new ValueOption('1', 'January'),
      new ValueOption('2', 'February'),
      new ValueOption('3', 'March'),
      new ValueOption('4', 'April'),
      new ValueOption('5', 'May'),
      new ValueOption('6', 'June'),
      new ValueOption('7', 'July'),
      new ValueOption('8', 'August'),
      new ValueOption('9', 'September'),
      new ValueOption('10', 'October'),
      new ValueOption('11', 'November'),
      new ValueOption('12', 'December'),
    ];

    return options;
  }

  public getYearOptionList(): ValueOptions {
    const years = new ValueOptions();
    const currentYear: number = new Date().getUTCFullYear();

    for (let i = 0; i <= 101; i++) {
      const year = currentYear + 1 - i;
      years.addOption(year.toString(), year.toString());
    }

    return years;
  }

  public convertOptionValueToNumeric(optionList: ValueOptions): ValueOptions {
    for (const option of optionList.options) {
      option.value = Number(option.value);
    }

    return optionList;
  }

  /**
   * Insert a blank option at the top of the list.
   */
  public addBlankOption(
    optionsList: ValueOptions,
    label: string = ''
  ): ValueOptions {
    optionsList.options.unshift(new ValueOption('', label));

    return optionsList;
  }

  public getFilerCategories(rulesVersion: string): ValueOptions {
    const filerCategories = new ValueOptions();

    if (!rulesVersion || rulesVersion < '201902') {
      filerCategories.options = [
        new ValueOption('', 'Please Select'),
        new ValueOption('career_ses', 'Career SES'),
        new ValueOption('non_career_ses', 'Non-Career SES'),
        new ValueOption('pas', 'PAS'),
        new ValueOption('daeo_pas', 'PAS DAEO'),
        new ValueOption('daeo_non_pas', 'DAEO Non-PAS'),
        new ValueOption('schedule_c', 'Schedule C'),
        new ValueOption('uniformed_services', 'Uniformed Services'),
        new ValueOption('other', 'Other'),
      ];
    } else {
      filerCategories.options = [
        new ValueOption('', 'Please Select'),
        new ValueOption('career_ses', 'Career SES'),
        new ValueOption('non_career_ses', 'Non-Career SES'),
        new ValueOption('pas', 'PAS'),
        new ValueOption('schedule_c', 'Schedule C'),
        new ValueOption('uniformed_services', 'Uniformed Services'),
        new ValueOption('other', 'Other'),
      ];
    }

    return filerCategories;
  }

  /**
   * Retrieve the value options from the spreadsheet and then filter for
   * Nominee values.
   *
   * @param optionValuesSetName
   */
  public getFilerCategoriesNominee(
    optionValuesSetName: string
  ): Observable<any> {
    const setName = optionValuesSetName.replace(/\sNominee/, '');

    const cached = this.cache.get(setName);
    if (cached) {
      return of(this.getFilerCategoriesFilteredForNominee(cached));
    }

    return this.getValueOptionsBySetName(setName).pipe(
      tap((optionValues) => {
        optionValues = this.getFilerCategoriesFilteredForNominee(optionValues);
      })
    );
  }

  getFilerCategoriesFilteredForNominee(
    optionValues: ValueOptions
  ): ValueOptions {
    optionValues.asArray = optionValues.asArray.filter((optionValue) =>
      optionValue.value.toString().match(/pas/i)
    );

    optionValues.asMap.forEach(
      (value: ValueOption, key: string, map: Map<string, ValueOption>) => {
        if (!key.toString().match(/pas/i)) {
          map.delete(key);
        }
      }
    );

    return optionValues;
  }

  /**
   * Rules Version >= 202408
   * Form Change 3 
   * OGE-8372
   */
  public getFilerAppointmentTypes(): Observable<ValueOptions> {
    const apptTypes = new ValueOptions();
   
    apptTypes.options = [
      new ValueOption('pas', 'PAS'),
      new ValueOption('career', 'Career'),
      new ValueOption('non_career', 'Non-Career'),
    ];

    return of(apptTypes); 
  }

  public getRulesVersion(optionValuesSetName: string): string {
    let rulesVersion = '';

    const match: any = optionValuesSetName.match(/v(.*)/);

    if (match != null && match.length) {
      rulesVersion = match[0];
    }
    return rulesVersion;
  }

  /**
   * JDG: The first parameter for ValueOption constructor is value and the second is display/label. If
   * any consumer expects a different order, then that component should be corrected, rather
   * than altering the order here by switching label with value.
   */
  public getSpouse(): ValueOptions {
    const option = new ValueOptions();
    option.options = [
      new ValueOption(
        'spouse',
        'I have a spouse and the spousal disclosure requirement applies.'
      ),
      new ValueOption('no_spouse', 'This requirement does not apply.'),
    ];

    return option;
  }

  getOptionContainsValue(
    matchValue: string,
    limit: number = -1
  ): Observable<any> {
    if (_.isEmpty(matchValue)) {
      return of(null);
    } else {
      const serviceEndpoint =
        this.searchServicePath + '/contains/' + matchValue + '.json';
      if (limit === -1) {
        limit = ConfigService.MAX_PAAS_VALUES_SEARCH_DEFAULT_LIMIT;
      }
      return this.http
        .get<any>(serviceEndpoint, {
          headers: this.sessionService.getAuthHeader(),
        })
        .pipe(
          map((data) => {
            data.results.items = data.results.items.slice(0, limit);
            return data;
          }),
          catchError(
            (error) =>
              'An error occurred while GETTING data from the ValuesFactory service for ' +
              serviceEndpoint +
              '.'
          )
        );
    }
  }

  getFilingTypeOptions(): Observable<Data> {
    const data: Data = {
      results: {
        array: new ValueOptions(),
        map: {},
      },
    };
    data.results.map.CONFIRMED_278 = 'PAS/DAEO';
    data.results.map.GENERAL_278 = 'General';

    data.results.array.addOption('CONFIRMED_278', 'PAS/DAEO');
    data.results.array.addOption('GENERAL_278', 'General');

    return of(data);
  }

  public getFilingTypes(): ValueOptions {
    const options = new ValueOptions();

    options.addOption('GENERAL_278', 'General');
    options.addOption('CONFIRMED_278', 'PAS/DAEO');

    return options;
  }

  public getEIFOptionList(): ValueOptions {
    const options = new ValueOptions();
    options.addOption(
      'not_an_investment_fund',
      'N/A = No underlying assets',
      'N/A'
    );
    options.addOption('eif', 'Y = EIF', 'Yes');
    options.addOption('not_an_eif', 'N = Not EIF', 'No');

    return options;
  }

  public getEIFGridOptionList(): ValueOptions {
    const options = new ValueOptions();

    options.addOption('not_an_investment_fund', 'N/A');
    options.addOption('eif', 'Yes');
    options.addOption('not_an_eif', 'No');

    return options;
  }

  public getIncomeTypeOptionList(): ValueOptions {
    const options = new ValueOptions();
    options.addOption(
      'not_applicable_less_than_201',
      'Not applicable (less than $201)'
    );
    options.addOption('separator', 'separator');
    options.addOption('dividends', 'Dividends');
    options.addOption('capital_gains', 'Capital Gains');
    options.addOption('interest', 'Interest');
    options.addOption('rent_or_royalties', 'Rent or Royalties');
    options.addOption('separator', 'separator');
    options.addOption('other', 'Other');

    return options;
  }

  public getIncomeAmountOptionList(): ValueOptions {
    const options = new ValueOptions();

    options.addOption('none_or_less_than_201', 'None (or less than $201)');
    options.addOption('201_1000', '$201 - $1,000');
    options.addOption('1001_2500', '$1,001 - $2,500');
    options.addOption('2501_5000', '$2,501 - $5,000');
    options.addOption('5001_15000', '$5,001 - $15,000');
    options.addOption('15001_50000', '$15,001 - $50,000');
    options.addOption('50001_100000', '$50,001 - $100,000');
    options.addOption('100001_1000000', '$100,001 - $1,000,000');
    options.addOption('1000001_5000000', '$1,000,001 - $5,000,000');
    options.addOption('over_5000000', 'Over $5,000,000');

    return options;
  }

  public getYesNoList(): ValueOptions {
    const options = new ValueOptions();
    options.addOption('yes', 'Yes');
    options.addOption('no', 'No');

    return options;
  }

  public getYesNoDoNotKnowList(): ValueOptions {
    const options = new ValueOptions();

    options.addOption('yes', 'Yes');
    options.addOption('no', 'No');
    options.addOption(
      'do_not_know',
      'Do not know – Your ethics official will need to speak with you further in order to determine whether the investment vehicle qualifies as an excepted investment fund.'
    );

    return options;
  }

  public getYesNoNotAscertainable(): ValueOptions {
    const options = new ValueOptions();

    options.addOption('yes', 'Yes');
    options.addOption(
      'no_a_value_is_not_readily_ascertainable',
      'No, a value is not readily ascertainable'
    );

    return options;
  }

  public getDivIntCapRentOther(): ValueOptions {
    const options = new ValueOptions();
    options.addOption('dividends', 'Dividends');
    options.addOption('interest', 'Interest');
    options.addOption('capital_gains', 'Capital Gains');
    options.addOption('rent_or_royalties', 'Rent or Royalties');
    options.addOption('separator', 'separator');
    options.addOption('other', 'Other');

    return options;
  }

  public getOrganizationTypes(): ValueOptions {
    const options = new ValueOptions();

    options.addOption('corporation', 'Corporation');
    options.addOption('law_firm_any_type', 'Law Firm');
    options.addOption('solo_legal_practice', 'Solo Legal Practice');
    options.addOption('non_profit', 'Non-Profit');
    options.addOption('trust', 'Trust');
    options.addOption('university_college', 'University/College');
    options.addOption('other', 'Other');

    return options;
  }

  /**
   * The positions types are the same as NonProfit
   */
  public getCorporationPositionTypes(): ValueOptions {
    return this.getNonProfitPositionTypes();
  }

  public getLawFirmPositionTypes(): ValueOptions {
    const options = new ValueOptions();

    options.addOption('attorney_law_firm', 'Attorney (law firm)');
    options.addOption('employee', 'Employee');
    options.addOption('consultant', 'Consultant');
    options.addOption('other', 'Other');

    return options;
  }

  public getSoloLegalPracticePositionTypes(): ValueOptions {
    const options = new ValueOptions();

    options.addOption('sole_proprietor', 'Sole Proprietor');
    options.addOption('other', 'Other');

    return options;
  }

  public getNonProfitPositionTypes(): ValueOptions {
    const options = new ValueOptions();

    options.addOption(
      'employee_officer_director',
      'Employee, Officer, or Director'
    );
    options.addOption('consultant', 'Consultant');
    options.addOption('other', 'Other');

    return options;
  }

  public getTrustPositionTypes(): ValueOptions {
    const options = new ValueOptions();

    options.addOption('trustee', 'Trustee');
    options.addOption('other', 'Other');

    return options;
  }

  public getUniversityCollegePositionTypes(): ValueOptions {
    const options = new ValueOptions();

    options.addOption('professor_or_dean', 'Professor or Dean');
    options.addOption(
      'employee_officer_director',
      'Employee, Officer, or Director'
    );
    options.addOption('consultant', 'Consultant');
    options.addOption('other', 'Other');

    return options;
  }

  public getOtherPositionTypes(): ValueOptions {
    const options = new ValueOptions();

    options.addOption('employee', 'Employee');
    options.addOption('other', 'Other');

    return options;
  }

  public getDocumentTypes(): ValueOptions {
    const options = new ValueOptions();

    options.addOption('job_description', 'Job description');
    options.addOption('ethics_agreement', 'Ethics Agreement');
    options.addOption(
      'extension_request_approval',
      'Extension request/approval'
    );
    options.addOption('waiver_request', 'Waiver request');
    options.addOption(
      'late_fee_waiver_request_disposition',
      'Late fee waiver request/disposition'
    );
    options.addOption(
      'certificate_of_divestiture',
      'Certificate of divestiture'
    );
    options.addOption('evidence_of_divestiture', 'Evidence of divestiture ');
    options.addOption(
      'resignation_from_outside_position',
      'Resignation from outside position'
    );
    options.addOption('recusal_disqualification', 'Recusal/Disqualification');
    options.addOption('208_waiver', '§ 208 waiver');
    options.addOption('reassignment', 'Reassignment');
    options.addOption(
      'notification_of_negotiations_for_post_government_compensation',
      'Notification of Negotiations for Post-Government Compensation (STOCK Act § 17)'
    );
    options.addOption('ethics_opinion', 'Ethics opinion');
    options.addOption('outside_activities', 'Outside activities');
    options.addOption('other', 'Other');

    return options;
  }

  public getRetentionPeriodOptions(): ValueOptions {
    const options = new ValueOptions();

    options.addOption('when_no_longer_needed', 'When no longer needed');
    options.addOption(
      '6_years_from_the_filing_date_of_the_report_related_to_this_document',
      '6 years from the filing date of the report related to this document'
    );
    options.addOption(
      'when_no_longer_needed_but_not_later_than_6_years_from_the_filing_date_of_the_report_related_to_this_document',
      'When no longer needed but not later than 6 years from the filing date of the report related to this document'
    );
    options.addOption(
      'when_no_longer_needed_but_not_later_than_6_years_from_the_filing_date_of_the_last_report_related_to_this_position',
      'When no longer needed but not later than 6 years from the filing date of the last report related to this position'
    );
    options.addOption(
      'when_no_longer_needed_but_not_later_than_6_years_from_the_filing_date_of_the_filers_last_report',
      'When no longer needed but not later than 6 years from the filing date of the filer’s last report'
    );

    return options;
  }

  public getFilerStatus(): ValueOptions {
    const options = new ValueOptions();

    options.addOption('full_time', 'Full-Time');
    options.addOption('part_time', 'Part-Time');
    options.addOption('sge', 'SGE');
    options.addOption('other', 'Other');

    return options;
  }

  public getExportFilingStatus(): ValueOptions {
    const options = new ValueOptions();
    options.addOption('draft', 'Draft');
    options.addOption('filed', 'Filed');
    return options;
  }

  public getExportItems(): ValueOptions {
    const options = new ValueOptions();

    options.addOption('Nominee', 'Nominee');
    options.addOption('nonNominee', '278e (non-Nominee) and 278-T');
    options.addOption('278e', '278e (non-Nominee)');
    options.addOption('278t', '278-T');

    return options;
  }

  public getPublicAvailability(): ValueOptions {
    const options = new ValueOptions();

    options.addOption('releaseable', 'Releaseable');
    options.addOption('not_releaseable', 'Not Releaseable');

    return options;
  }

  public getPostOrDoNotPost(): ValueOptions {
    const options = new ValueOptions();

    options.addOption('post', 'Post');
    options.addOption('do_not_post', 'Do not post');

    return options;
  }

  /**
   * ~ GridService.js setGroupNameOptionList
   * EFEDS-4253
   */
  public getGroupNameOptions(): Observable<any> {
    return this.queryService.getGroupNames().pipe(
      map((groups: any) => {
        let options: ValueOptions = new ValueOptions();

        groups.forEach((group: any) => {
          options.addOption(group.groupId, group.name);
        });

        return options;
      })
    );
  }

  public getItemOwnerOptions(): ValueOptions {
    const options = new ValueOptions();

    options.addOption('my_asset', 'Filer (Me)', 'F');
    options.addOption('spouse', 'Spouse', 'S');
    options.addOption('joint', 'Joint', 'J');
    options.addOption('dependent_child', 'Dependent Child', 'D');

    return options;
  }

  public getItemOwnerGridOptions(): ValueOptions {
    const options = new ValueOptions();

    options.addOption('my_asset', 'F');
    options.addOption('spouse', 'S');
    options.addOption('joint', 'J');
    options.addOption('dependent_child', 'D');

    return options;
  }

  public getTypeOfTransactionOptions(): ValueOptions {
    const options = new ValueOptions();

    options.addOption('purchase', 'Purchase');
    options.addOption('sale', 'Sale');
    options.addOption('exchange', 'Exchange ');

    return options;
  }

  public getTransactionAmountOptions(): ValueOptions {
    const options = new ValueOptions();

    options.addOption('1001_15000', '$1,001 - $15,000');
    options.addOption('15001_50000', '$15,001 - $50,00');
    options.addOption('50001_100000', '$50,001 - $100,000');
    options.addOption('100001_250000', '$100,001 - $250,000');
    options.addOption('250001_500000', '$250,001 - $500,000');
    options.addOption('500001_1000000', '$500,001 - $1,000,000');
    options.addOption(
      'over_1000000',
      'Over $1,000,000 (for spouse/dependent child only)'
    );
    options.addOption('1000001_5000000', '$1,000,001 - $5,000,000');
    options.addOption('5000001_25000000', '$5,000,001 - $25,000,000');
    options.addOption('25000001_50000000', '$25,000,001 - $50,000,000');
    options.addOption('over_50000000', 'Over $50,000,000');

    return options;
  }

  public getTransactionAmountGridOptions(): ValueOptions {
    const options = new ValueOptions();

    options.addOption('1001_15000', '$1,001 - $15,000');
    options.addOption('15001_50000', '$15,001 - $50,00');
    options.addOption('50001_100000', '$50,001 - $100,000');
    options.addOption('100001_250000', '$100,001 - $250,000');
    options.addOption('250001_500000', '$250,001 - $500,000');
    options.addOption('500001_1000000', '$500,001 - $1,000,000');
    options.addOption('over_1000000', 'Over $1,000,000');
    options.addOption('1000001_5000000', '$1,000,001 - $5,000,000');
    options.addOption('5000001_25000000', '$5,000,001 - $25,000,000');
    options.addOption('25000001_50000000', '$25,000,001 - $50,000,000');
    options.addOption('over_50000000', 'Over $50,000,000');

    return options;
  }

  public getTypeOfLiabilityOptions(): ValueOptions {
    const options = new ValueOptions();

    options.addOption('credit_card', 'Credit Card');
    options.addOption('student_loan', 'Student Loan');
    options.addOption(
      'mortgage_investment_rental_property',
      'Mortgage (investment/rental property)'
    );
    options.addOption(
      'mortgage_on_personal_residence',
      'Mortgage on Personal Residence'
    );
    options.addOption('exercsied_line_of_credit', 'Exercised Line of Credit');
    options.addOption('personal_loan', 'Personal Loan');
    options.addOption('other', 'Other');

    return options;
  }

  public getHours(): ValueOptions {
    const options = new ValueOptions();

    let hour: string = '';
    let i: number = 0;

    for (i = 0; i < 13; i++) {
      hour = i.toString().padStart(2, '0');
      options.addOption(hour, hour);
    }

    return options;
  }

  public getMinutes(): ValueOptions {
    const options = new ValueOptions();

    let minute: string = '';
    let i: number = 0;

    for (i = 0; i < 60; i++) {
      minute = i.toString().padStart(2, '0');
      options.addOption(minute, minute);
    }

    return options;
  }

  public getMeridiem(): ValueOptions {
    const options = new ValueOptions();

    options.addOption('0', 'am');
    options.addOption('12', 'pm');

    return options;
  }

  public getStates(): ValueOptions {
    const options = new ValueOptions();
    options.addOption('Alabama', 'Alabama');
    options.addOption('Alaska', 'Alaska');
    options.addOption('American Samoa', 'American Samoa');
    options.addOption('Arizona', 'Arizona');
    options.addOption('Arkansas', 'Arkansas');
    options.addOption('Armed Forces - Americas', 'Armed Forces - Americas');
    options.addOption('Armed Forces - Europe', 'Armed Forces - Europe');
    options.addOption('Armed Forces - Pacific', 'Armed Forces - Pacific');
    options.addOption('California', 'California');
    options.addOption('Colorado', 'Colorado');
    options.addOption('Connecticut', 'Connecticut');
    options.addOption('Delaware', 'Delaware');
    options.addOption('District of Columbia', 'District of Columbia');
    options.addOption('Florida', 'Florida');
    options.addOption('Georgia', 'Georgia');
    options.addOption('Guam', 'Guam');
    options.addOption('Hawaii', 'Hawaii');
    options.addOption('Idaho', 'Idaho');
    options.addOption('Illinois', 'Illinois');
    options.addOption('Indiana', 'Indiana');
    options.addOption('Iowa', 'Iowa');
    options.addOption('Kansas', 'Kansas');
    options.addOption('Kentucky', 'Kentucky');
    options.addOption('Louisiana', 'Louisiana');
    options.addOption('Maine', 'Maine');
    options.addOption('Maryland', 'Maryland');
    options.addOption('Massachusetts', 'Massachusetts');
    options.addOption('Michigan', 'Michigan');
    options.addOption('Minnesota', 'Minnesota');
    options.addOption('Mississippi', 'Mississippi');
    options.addOption('Missouri', 'Missouri');
    options.addOption('Montana', 'Montana');
    options.addOption('Nebraska', 'Nebraska');
    options.addOption('Nevada', 'Nevada');
    options.addOption('New Hampshire', 'New Hampshire');
    options.addOption('New Jersey', 'New Jersey');
    options.addOption('New Mexico', 'New Mexico');
    options.addOption('New York', 'New York');
    options.addOption('North Carolina', 'North Carolina');
    options.addOption('North Dakota', 'North Dakota');
    options.addOption('Northern Mariana Islands', 'Northern Mariana Islands');
    options.addOption('Ohio', 'Ohio');
    options.addOption('Oklahoma', 'Oklahoma');
    options.addOption('Oregon', 'Oregon');
    options.addOption('Pennsylvania', 'Pennsylvania');
    options.addOption('Puerto Rico', 'Puerto Rico');
    options.addOption('Rhode Island', 'Rhode Island');
    options.addOption('South Carolina', 'South Carolina');
    options.addOption('South Dakota', 'South Dakota');
    options.addOption('Tennessee', 'Tennessee');
    options.addOption('Texas', 'Texas');
    options.addOption('Utah', 'Utah');
    options.addOption('Vermont', 'Vermont');
    options.addOption('Virgin Islands', 'Virgin Islands');
    options.addOption('Virginia', 'Virginia');
    options.addOption('Washington', 'Washington');
    options.addOption('West Virginia', 'West Virginia');
    options.addOption('Wisconsin', 'Wisconsin');
    options.addOption('Wyoming', 'Wyoming');
    options.addOption('Outside U.S.', 'Outside U.S.');

    return options;
  }

  /**
   * Get the country options from the max list as a set of the integrity values
   * as the value and also the integrity values as the display string.
   * OGE-7798
   */
  getCountryOptions(): ValueOptions {
    const options = new ValueOptions();
    this.getMaxCountryOptions().forEach((country) => {
      options.addOption(country.integrityValue, country.integrityValue);
    });
    return options;
  }
  
  getCountries(): ValueOptions {
    let index = 0;
    const options = new ValueOptions();
    this.getMaxCountryOptions().forEach((country) => {
      options.addOption((index++).toString(), country.integrityValue);
    });
    return options;
  }

  public getDCPParticipation(): ValueOptions {
    const options = new ValueOptions();

    options.addOption(
      'yes',
      'Yes (i.e., I will retain an account with this plan)'
    );
    options.addOption('no', 'No (i.e., I will roll my funds out of the plan)');
    options.addOption(
      'not_applicable',
      'Not applicable - I no longer participate in this defined contribution plan.'
    );

    return options;
  }

  public getDCPContributions(): ValueOptions {
    const options = new ValueOptions();

    options.addOption('yes', 'Yes');
    options.addOption(
      'no_contributions_will_cease_upon_my_separation',
      'No - Contributions will cease upon my separation.'
    );
    options.addOption(
      'not_applicable_i_hold_this_plan_through_a_former_employer_so_no_contributions_are_being_made_currently',
      'Not applicable - I hold this plan through a former employer, so no contributions are being made currently.'
    );

    return options;
  }

  /**
   * Return unique set of all filer category versions to use in a drop down filter or to
   * get display value when one or more filings could have been assigned with a different version.
   * https://stackoverflow.com/questions/2218999/how-to-remove-all-duplicates-from-an-array-of-objects
   */
  public getFilerCategoryAll(): Observable<any> {
    const optionValues: ValueOptions = new ValueOptions();
    let options: Array<ValueOption> = [];

    return forkJoin([
      this.getValueOptionsBySetName('Filer Category v201902'),
      this.getValueOptionsBySetName('Filer Category'),
    ]).pipe(
      map((result) => {
        options = [...result[0].asArray, ...result[1].asArray];

        options = options.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.value === value.value)
        );
        options.forEach((option) => {
          optionValues.addOption(
            option.value as string,
            option.display,
            option.gridDisplay
          );
        });
        return optionValues;
      })
    );
  }

  getMaxCountryOptions(): {
    maxValue: string;
    maxLabel: string;
    integrityValue: string;
  }[] {
    return [
      {
        maxValue: 'US',
        maxLabel: 'United States of America',
        integrityValue: 'United States',
      },
      {
        maxValue: 'AF',
        maxLabel: 'Afghanistan',
        integrityValue: 'Afghanistan',
      },
      {
        maxValue: 'AX',
        maxLabel: 'Åland Islands',
        integrityValue: 'Åland Islands',
      },
      { maxValue: 'AL', maxLabel: 'Albania', integrityValue: 'Albania' },
      { maxValue: 'DZ', maxLabel: 'Algeria', integrityValue: 'Algeria' },
      { maxValue: 'AD', maxLabel: 'Andorra', integrityValue: 'Andorra' },
      { maxValue: 'AO', maxLabel: 'Angola', integrityValue: 'Angola' },
      { maxValue: 'AI', maxLabel: 'Anguilla', integrityValue: 'Anguilla' },
      { maxValue: 'AQ', maxLabel: 'Antarctica', integrityValue: 'Antarctica' },
      {
        maxValue: 'AG',
        maxLabel: 'Antigua and Barbuda',
        integrityValue: 'Antigua and Barbuda',
      },
      { maxValue: 'AR', maxLabel: 'Argentina', integrityValue: 'Argentina' },
      { maxValue: 'AM', maxLabel: 'Armenia', integrityValue: 'Armenia' },
      { maxValue: 'AW', maxLabel: 'Aruba', integrityValue: 'Aruba' },
      { maxValue: 'AU', maxLabel: 'Australia', integrityValue: 'Australia' },
      { maxValue: 'AT', maxLabel: 'Austria', integrityValue: 'Austria' },
      { maxValue: 'AZ', maxLabel: 'Azerbaijan', integrityValue: 'Azerbaijan' },
      {
        maxValue: 'BS',
        maxLabel: 'Bahamas (the)',
        integrityValue: 'Bahamas',
      },
      { maxValue: 'BH', maxLabel: 'Bahrain', integrityValue: 'Bahrain' },
      { maxValue: 'BD', maxLabel: 'Bangladesh', integrityValue: 'Bangladesh' },
      { maxValue: 'BB', maxLabel: 'Barbados', integrityValue: 'Barbados' },
      { maxValue: 'BY', maxLabel: 'Belarus', integrityValue: 'Belarus' },
      { maxValue: 'BE', maxLabel: 'Belgium', integrityValue: 'Belgium' },
      { maxValue: 'BZ', maxLabel: 'Belize', integrityValue: 'Belize' },
      { maxValue: 'BJ', maxLabel: 'Benin', integrityValue: 'Benin' },
      { maxValue: 'BM', maxLabel: 'Bermuda', integrityValue: 'Bermuda' },
      { maxValue: 'BT', maxLabel: 'Bhutan', integrityValue: 'Bhutan' },
      {
        maxValue: 'BO',
        maxLabel: 'Bolivia (Plurinational State of)',
        integrityValue: 'Bolivia',
      },
      {
        maxValue: 'BQ',
        maxLabel: 'Bonaire, Sint Eustatius and Saba',
        integrityValue: 'Bonaire, Sint Eustatius and Saba',
      },
      {
        maxValue: 'BA',
        maxLabel: 'Bosnia and Herzegovina',
        integrityValue: 'Bosnia and Herzegovina',
      },
      { maxValue: 'BW', maxLabel: 'Botswana', integrityValue: 'Botswana' },
      {
        maxValue: 'BV',
        maxLabel: 'Bouvet Island',
        integrityValue: 'Bouvet Island',
      },
      { maxValue: 'BR', maxLabel: 'Brazil', integrityValue: 'Brazil' },
      {
        maxValue: 'IO',
        maxLabel: 'British Indian Ocean Territory',
        integrityValue: 'British Indian Ocean Territory',
      },
      {
        maxValue: 'BN',
        maxLabel: 'Brunei Darussalam',
        integrityValue: 'Brunei Darussalam',
      },
      { maxValue: 'BG', maxLabel: 'Bulgaria', integrityValue: 'Bulgaria' },
      {
        maxValue: 'BF',
        maxLabel: 'Burkina Faso',
        integrityValue: 'Burkina Faso',
      },
      { maxValue: 'BI', maxLabel: 'Burundi', integrityValue: 'Burundi' },
      { maxValue: 'CV', maxLabel: 'Cabo Verde', integrityValue: 'Cabo Verde' },
      { maxValue: 'KH', maxLabel: 'Cambodia', integrityValue: 'Cambodia' },
      { maxValue: 'CM', maxLabel: 'Cameroon', integrityValue: 'Cameroon' },
      { maxValue: 'CA', maxLabel: 'Canada', integrityValue: 'Canada' },
      {
        maxValue: 'KY',
        maxLabel: 'Cayman Islands (the)',
        integrityValue: 'Cayman Islands',
      },
      {
        maxValue: 'CF',
        maxLabel: 'Central African Republic (the)',
        integrityValue: 'Central African Republic',
      },
      { maxValue: 'TD', maxLabel: 'Chad', integrityValue: 'Chad' },
      { maxValue: 'CL', maxLabel: 'Chile', integrityValue: 'Chile' },
      { maxValue: 'CN', maxLabel: 'China', integrityValue: 'China' },
      {
        maxValue: 'CX',
        maxLabel: 'Christmas Island',
        integrityValue: 'Christmas Island',
      },
      {
        maxValue: 'CC',
        maxLabel: 'Cocos (Keeling) Islands (the)',
        integrityValue: 'Cocos (Keeling) Islands',
      },
      {
        maxValue: 'CO',
        maxLabel: 'Colombia (Republic of)',
        integrityValue: 'Colombia',
      },
      { maxValue: 'KM', maxLabel: 'Comoros', integrityValue: 'Comoros' },
      {
        maxValue: 'CG',
        maxLabel: 'Congo',
        integrityValue: 'Congo',
      },
      {
        maxValue: 'CD',
        maxLabel: 'Congo (the Democratic Republic of the)',
        integrityValue: 'Congo, Democratic Republic of the',
      },
      {
        maxValue: 'CK',
        maxLabel: 'Cook Islands',
        integrityValue: 'Cook Islands',
      },
      { maxValue: 'CR', maxLabel: 'Costa Rica', integrityValue: 'Costa Rica' },
      {
        maxValue: 'CI',
        maxLabel: "Cote d'Ivoire",
        integrityValue: "Cote d'Ivoire",
      },
      { maxValue: 'HR', maxLabel: 'Croatia', integrityValue: 'Croatia' },
      { maxValue: 'CU', maxLabel: 'Cuba', integrityValue: 'Cuba' },
      { maxValue: 'CW', maxLabel: 'Curaçao', integrityValue: 'Curaçao' },
      { maxValue: 'CY', maxLabel: 'Cyprus', integrityValue: 'Cyprus' },
      {
        maxValue: 'CZ',
        maxLabel: 'Czech Republic (the)',
        integrityValue: 'Czech Republic',
      },
      { maxValue: 'DK', maxLabel: 'Denmark', integrityValue: 'Denmark' },
      { maxValue: 'DJ', maxLabel: 'Djibouti', integrityValue: 'Djibouti' },
      { maxValue: 'DM', maxLabel: 'Dominica', integrityValue: 'Dominica' },
      {
        maxValue: 'DO',
        maxLabel: 'Dominican Republic (the)',
        integrityValue: 'Dominican Republic',
      },
      { maxValue: 'EC', maxLabel: 'Ecuador', integrityValue: 'Ecuador' },
      { maxValue: 'EG', maxLabel: 'Egypt', integrityValue: 'Egypt' },
      {
        maxValue: 'SV',
        maxLabel: 'El Salvador',
        integrityValue: 'El Salvador',
      },
      {
        maxValue: 'GQ',
        maxLabel: 'Equatorial Guinea',
        integrityValue: 'Equatorial Guinea',
      },
      { maxValue: 'ER', maxLabel: 'Eritrea', integrityValue: 'Eritrea' },
      { maxValue: 'EE', maxLabel: 'Estonia', integrityValue: 'Estonia' },
      { maxValue: 'ET', maxLabel: 'Ethiopia', integrityValue: 'Ethiopia' },
      {
        maxValue: 'FK',
        maxLabel: 'Falkland Islands (the)',
        integrityValue: 'Falkland Islands',
      },
      {
        maxValue: 'FO',
        maxLabel: 'Faroe Islands',
        integrityValue: 'Faroe Islands',
      },
      { maxValue: 'FJ', maxLabel: 'Fiji', integrityValue: 'Fiji' },
      { maxValue: 'FI', maxLabel: 'Finland', integrityValue: 'Finland' },
      { maxValue: 'FR', maxLabel: 'France', integrityValue: 'France' },
      {
        maxValue: 'GF',
        maxLabel: 'French Guiana',
        integrityValue: 'French Guiana',
      },
      {
        maxValue: 'PF',
        maxLabel: 'French Polynesia',
        integrityValue: 'French Polynesia',
      },
      {
        maxValue: 'TF',
        maxLabel: 'French Southern Territories (the)',
        integrityValue: 'French Southern Territories',
      },
      { maxValue: 'GA', maxLabel: 'Gabon', integrityValue: 'Gabon' },
      { maxValue: 'GM', maxLabel: 'Gambia', integrityValue: 'Gambia' },
      { maxValue: 'GE', maxLabel: 'Georgia', integrityValue: 'Georgia' },
      { maxValue: 'DE', maxLabel: 'Germany', integrityValue: 'Germany' },
      { maxValue: 'GH', maxLabel: 'Ghana', integrityValue: 'Ghana' },
      { maxValue: 'GI', maxLabel: 'Gibraltar', integrityValue: 'Gibraltar' },
      { maxValue: 'GR', maxLabel: 'Greece', integrityValue: 'Greece' },
      { maxValue: 'GL', maxLabel: 'Greenland', integrityValue: 'Greenland' },
      { maxValue: 'GD', maxLabel: 'Grenada', integrityValue: 'Grenada' },
      { maxValue: 'GP', maxLabel: 'Guadeloupe', integrityValue: 'Guadeloupe' },
      { maxValue: 'GT', maxLabel: 'Guatemala', integrityValue: 'Guatemala' },
      { maxValue: 'GG', maxLabel: 'Guernsey', integrityValue: 'Guernsey' },
      { maxValue: 'GN', maxLabel: 'Guinea', integrityValue: 'Guinea' },
      {
        maxValue: 'GW',
        maxLabel: 'Guinea-Bissau',
        integrityValue: 'Guinea-Bissau',
      },
      { maxValue: 'GY', maxLabel: 'Guyana', integrityValue: 'Guyana' },
      { maxValue: 'HT', maxLabel: 'Haiti', integrityValue: 'Haiti' },
      {
        maxValue: 'HM',
        maxLabel: 'Heard Island and McDonald Islands',
        integrityValue: 'Heard Island and McDonald Islands',
      },
      {
        maxValue: 'VA',
        maxLabel: 'Holy See (the) [Vatican City State]',
        integrityValue: 'Holy See [Vatican City State]',
      },
      { maxValue: 'HN', maxLabel: 'Honduras', integrityValue: 'Honduras' },
      { maxValue: 'HK', maxLabel: 'Hong Kong', integrityValue: 'Hong Kong' },
      { maxValue: 'HU', maxLabel: 'Hungary', integrityValue: 'Hungary' },
      { maxValue: 'IS', maxLabel: 'Iceland', integrityValue: 'Iceland' },
      { maxValue: 'IN', maxLabel: 'India', integrityValue: 'India' },
      {
        maxValue: 'ID',
        maxLabel: 'Indonesia (includes Portuguese Timor)',
        integrityValue: 'Indonesia',
      },
      {
        maxValue: 'IR',
        maxLabel: 'Iran (the Islamic Republic of)',
        integrityValue: 'Iran',
      },
      { maxValue: 'IQ', maxLabel: 'Iraq', integrityValue: 'Iraq' },
      { maxValue: 'IE', maxLabel: 'Ireland', integrityValue: 'Ireland' },
      {
        maxValue: 'IM',
        maxLabel: 'Isle of Man',
        integrityValue: 'Isle of Man',
      },
      { maxValue: 'IL', maxLabel: 'Israel', integrityValue: 'Israel' },
      { maxValue: 'IT', maxLabel: 'Italy', integrityValue: 'Italy' },
      { maxValue: 'JM', maxLabel: 'Jamaica', integrityValue: 'Jamaica' },
      { maxValue: 'JP', maxLabel: 'Japan', integrityValue: 'Japan' },
      { maxValue: 'JE', maxLabel: 'Jersey', integrityValue: 'Jersey' },
      { maxValue: 'JO', maxLabel: 'Jordan', integrityValue: 'Jordan' },
      { maxValue: 'KZ', maxLabel: 'Kazakhstan', integrityValue: 'Kazakhstan' },
      { maxValue: 'KE', maxLabel: 'Kenya', integrityValue: 'Kenya' },
      { maxValue: 'KI', maxLabel: 'Kiribati', integrityValue: 'Kiribati' },
      {
        maxValue: 'KP',
        maxLabel: "Korea (the Democractic People's Republic of)",
        integrityValue: "Korea (Democratic People's Republic of)",
      },
      {
        maxValue: 'KR',
        maxLabel: 'Korea (the Republic of)',
        integrityValue: 'Korea (Republic of)',
      },
      { maxValue: 'XK', maxLabel: 'Kosovo', integrityValue: 'Kosovo' },
      { maxValue: 'KW', maxLabel: 'Kuwait', integrityValue: 'Kuwait' },
      { maxValue: 'KG', maxLabel: 'Kyrgyzstan', integrityValue: 'Kyrgyzstan' },
      {
        maxValue: 'LA',
        maxLabel: "Lao People's Democratic Republic (the)",
        integrityValue: "Lao People's Democratic Republic",
      },
      { maxValue: 'LV', maxLabel: 'Latvia', integrityValue: 'Latvia' },
      { maxValue: 'LB', maxLabel: 'Lebanon', integrityValue: 'Lebanon' },
      { maxValue: 'LS', maxLabel: 'Lesotho', integrityValue: 'Lesotho' },
      { maxValue: 'LR', maxLabel: 'Liberia', integrityValue: 'Liberia' },
      { maxValue: 'LY', maxLabel: 'Libya', integrityValue: 'Libya' },
      {
        maxValue: 'LI',
        maxLabel: 'Liechtenstein',
        integrityValue: 'Liechtenstein',
      },
      { maxValue: 'LT', maxLabel: 'Lithuania', integrityValue: 'Lithuania' },
      { maxValue: 'LU', maxLabel: 'Luxembourg', integrityValue: 'Luxembourg' },
      { maxValue: 'MO', maxLabel: 'Macao', integrityValue: 'Macao' },
      {
        maxValue: 'MK',
        maxLabel: 'Macedonia (the former Yugoslav Republic of)',
        integrityValue: 'North Macedonia',
      },
      { maxValue: 'MG', maxLabel: 'Madagascar', integrityValue: 'Madagascar' },
      { maxValue: 'MV', maxLabel: 'Maldives', integrityValue: 'Maldives' },
      { maxValue: 'MW', maxLabel: 'Malawi', integrityValue: 'Malawi' },
      { maxValue: 'MY', maxLabel: 'Malaysia', integrityValue: 'Malaysia' },
      { maxValue: 'ML', maxLabel: 'Mali', integrityValue: 'Mali' },
      { maxValue: 'MT', maxLabel: 'Malta', integrityValue: 'Malta' },
      {
        maxValue: 'MH',
        maxLabel: 'Marshall Islands (the)',
        integrityValue: 'Marshall Islands',
      },
      { maxValue: 'MQ', maxLabel: 'Martinique', integrityValue: 'Martinique' },
      { maxValue: 'MR', maxLabel: 'Mauritania', integrityValue: 'Mauritania' },
      { maxValue: 'MU', maxLabel: 'Mauritius', integrityValue: 'Mauritius' },
      { maxValue: 'YT', maxLabel: 'Mayotte', integrityValue: 'Mayotte' },
      { maxValue: 'MX', maxLabel: 'Mexico', integrityValue: 'Mexico' },
      {
        maxValue: 'FM',
        maxLabel: 'Micronesia (the Federated States of)',
        integrityValue: 'Micronesia (Federated States of)',
      },
      {
        maxValue: 'MD',
        maxLabel: 'Moldova (the Republic of)',
        integrityValue: 'Moldova',
      },
      { maxValue: 'MC', maxLabel: 'Monaco', integrityValue: 'Monaco' },
      { maxValue: 'MN', maxLabel: 'Mongolia', integrityValue: 'Mongolia' },
      { maxValue: 'ME', maxLabel: 'Montenegro', integrityValue: 'Montenegro' },
      { maxValue: 'MS', maxLabel: 'Montserrat', integrityValue: 'Montserrat' },
      { maxValue: 'MA', maxLabel: 'Morocco', integrityValue: 'Morocco' },
      { maxValue: 'MZ', maxLabel: 'Mozambique', integrityValue: 'Mozambique' },
      { maxValue: 'MM', maxLabel: 'Myanmar', integrityValue: 'Myanmar' },
      { maxValue: 'NA', maxLabel: 'Namibia', integrityValue: 'Namibia' },
      { maxValue: 'NR', maxLabel: 'Nauru', integrityValue: 'Nauru' },
      { maxValue: 'NP', maxLabel: 'Nepal', integrityValue: 'Nepal' },
      {
        maxValue: 'NL',
        maxLabel: 'Netherlands (the)',
        integrityValue: 'Netherlands',
      },
      {
        maxValue: 'NC',
        maxLabel: 'New Caledonia',
        integrityValue: 'New Caledonia',
      },
      {
        maxValue: 'NZ',
        maxLabel: 'New Zealand',
        integrityValue: 'New Zealand',
      },
      { maxValue: 'NI', maxLabel: 'Nicaragua', integrityValue: 'Nicaragua' },
      { maxValue: 'NE', maxLabel: 'Niger (the)', integrityValue: 'Niger' },
      { maxValue: 'NG', maxLabel: 'Nigeria', integrityValue: 'Nigeria' },
      { maxValue: 'NU', maxLabel: 'Niue', integrityValue: 'Niue' },
      {
        maxValue: 'NF',
        maxLabel: 'Norfolk Islands',
        integrityValue: 'Norfolk Islands',
      },
      { maxValue: 'NO', maxLabel: 'Norway', integrityValue: 'Norway' },
      { maxValue: 'OM', maxLabel: 'Oman', integrityValue: 'Oman' },
      { maxValue: 'PK', maxLabel: 'Pakistan', integrityValue: 'Pakistan' },
      { maxValue: 'PW', maxLabel: 'Palau', integrityValue: 'Palau' },
      {
        maxValue: 'PS',
        maxLabel: 'Palestine (State of)',
        integrityValue: 'Palestine, State of',
      },
      { maxValue: 'PA', maxLabel: 'Panama', integrityValue: 'Panama' },
      {
        maxValue: 'PG',
        maxLabel: 'Papua New Guinea',
        integrityValue: 'Papua New Guinea',
      },
      { maxValue: 'PY', maxLabel: 'Paraguay', integrityValue: 'Paraguay' },
      { maxValue: 'PE', maxLabel: 'Peru', integrityValue: 'Peru' },
      {
        maxValue: 'PH',
        maxLabel: 'Philippines (the)',
        integrityValue: 'Philippines',
      },
      {
        maxValue: 'PN',
        maxLabel: 'Pitcairn Islands',
        integrityValue: 'Pitcairn Islands',
      },
      { maxValue: 'PL', maxLabel: 'Poland', integrityValue: 'Poland' },
      { maxValue: 'PT', maxLabel: 'Portugal', integrityValue: 'Portugal' },
      { maxValue: 'QA', maxLabel: 'Qatar', integrityValue: 'Qatar' },
      { maxValue: 'RE', maxLabel: 'Reunion', integrityValue: 'Reunion' },
      { maxValue: 'RO', maxLabel: 'Romania', integrityValue: 'Romania' },
      {
        maxValue: 'RU',
        maxLabel: 'Russian Federation (the)',
        integrityValue: 'Russian Federation',
      },
      { maxValue: 'RW', maxLabel: 'Rwanda', integrityValue: 'Rwanda' },
      {
        maxValue: 'BL',
        maxLabel: 'Saint Barthelemy',
        integrityValue: 'Saint Barthelemy',
      },
      {
        maxValue: 'SH',
        maxLabel: 'Saint Helena, Ascension and Tristan da Cunha',
        integrityValue: 'Saint Helena, Ascension and Tristan da Cunha',
      },
      {
        maxValue: 'KN',
        maxLabel: 'Saint Kitts and Nevis',
        integrityValue: 'Saint Kitts and Nevis',
      },
      {
        maxValue: 'LC',
        maxLabel: 'Saint Lucia',
        integrityValue: 'Saint Lucia',
      },
      {
        maxValue: 'MF',
        maxLabel: 'Saint Martin (French part)',
        integrityValue: 'Saint Martin',
      },
      {
        maxValue: 'PM',
        maxLabel: 'Saint Pierre and Miquelon',
        integrityValue: 'Saint Pierre and Miquelon',
      },
      {
        maxValue: 'VC',
        maxLabel: 'Saint Vincent and the Grenadines',
        integrityValue: 'Saint Vincent and the Grenadines',
      },
      { maxValue: 'WS', maxLabel: 'Samoa', integrityValue: 'Samoa' },
      { maxValue: 'SM', maxLabel: 'San Marino', integrityValue: 'San Marino' },
      {
        maxValue: 'ST',
        maxLabel: 'São Tomé and Príncipe',
        integrityValue: 'São Tomé and Príncipe',
      },
      {
        maxValue: 'SA',
        maxLabel: 'Saudi Arabia',
        integrityValue: 'Saudi Arabia',
      },
      { maxValue: 'SN', maxLabel: 'Senegal', integrityValue: 'Senegal' },
      {
        maxValue: 'RS',
        maxLabel: 'Serbia',
        integrityValue: 'Serbia',
      },
      { maxValue: 'SC', maxLabel: 'Seychelles', integrityValue: 'Seychelles' },
      {
        maxValue: 'SL',
        maxLabel: 'Sierra Leone',
        integrityValue: 'Sierra Leone',
      },
      { maxValue: 'SG', maxLabel: 'Singapore', integrityValue: 'Singapore' },
      {
        maxValue: 'SX',
        maxLabel: 'Sint Maarten (Dutch part)',
        integrityValue: 'Sint Maarten',
      },
      { maxValue: 'SK', maxLabel: 'Slovakia', integrityValue: 'Slovakia' },
      { maxValue: 'SI', maxLabel: 'Slovenia', integrityValue: 'Slovenia' },
      {
        maxValue: 'SB',
        maxLabel: 'Solomon Islands (the)',
        integrityValue: 'Solomon Islands',
      },
      { maxValue: 'SO', maxLabel: 'Somalia', integrityValue: 'Somalia' },
      {
        maxValue: 'ZA',
        maxLabel: 'South Africa',
        integrityValue: 'South Africa',
      },
      {
        maxValue: 'GS',
        maxLabel: 'South Georgia and the South Sandwich Islands',
        integrityValue: 'South Georgia and the South Sandwich Islands',
      },
      {
        maxValue: 'SS',
        maxLabel: 'South Sudan',
        integrityValue: 'South Sudan',
      },
      { maxValue: 'ES', maxLabel: 'Spain', integrityValue: 'Spain' },
      { maxValue: 'LK', maxLabel: 'Sri Lanka', integrityValue: 'Sri Lanka' },
      { maxValue: 'SD', maxLabel: 'Sudan', integrityValue: 'Sudan' },
      { maxValue: 'SR', maxLabel: 'Suriname', integrityValue: 'Suriname' },
      {
        maxValue: 'SJ',
        maxLabel: 'Svalbard and Jan Mayen',
        integrityValue: 'Svalbard and Jan Mayen',
      },
      { maxValue: 'SZ', maxLabel: 'Swaziland', integrityValue: 'Swaziland' },
      { maxValue: 'SE', maxLabel: 'Sweden', integrityValue: 'Sweden' },
      {
        maxValue: 'CH',
        maxLabel: 'Switzerland',
        integrityValue: 'Switzerland',
      },
      {
        maxValue: 'SY',
        maxLabel: 'Syrian Arab Republic',
        integrityValue: 'Syrian Arab Republic',
      },
      {
        maxValue: 'TW',
        maxLabel: 'Taiwan (Province of China)',
        integrityValue: 'Taiwan',
      },
      { maxValue: 'TJ', maxLabel: 'Tajikistan', integrityValue: 'Tajikistan' },
      {
        maxValue: 'TZ',
        maxLabel: 'Tanzania (United Republic of)',
        integrityValue: 'Tanzania',
      },
      { maxValue: 'TH', maxLabel: 'Thailand', integrityValue: 'Thailand' },
      {
        maxValue: 'TL',
        maxLabel: 'Timor-Leste',
        integrityValue: 'Timor-Leste',
      },
      { maxValue: 'TG', maxLabel: 'Togo', integrityValue: 'Togo' },
      { maxValue: 'TK', maxLabel: 'Tokelau', integrityValue: 'Tokelau' },
      { maxValue: 'TO', maxLabel: 'Tonga', integrityValue: 'Tonga' },
      {
        maxValue: 'TT',
        maxLabel: 'Trinidad and Tobago',
        integrityValue: 'Trinidad and Tobago',
      },
      { maxValue: 'TN', maxLabel: 'Tunisia', integrityValue: 'Tunisia' },
      { maxValue: 'TR', maxLabel: 'Turkey', integrityValue: 'Turkey' },
      {
        maxValue: 'TM',
        maxLabel: 'Turkmenistan',
        integrityValue: 'Turkmenistan',
      },
      {
        maxValue: 'TC',
        maxLabel: 'Turks and Caicos Islands (the)',
        integrityValue: 'Turks and Caicos Islands',
      },
      { maxValue: 'TV', maxLabel: 'Tuvalu', integrityValue: 'Tuvalu' },
      { maxValue: 'UG', maxLabel: 'Uganda', integrityValue: 'Uganda' },
      { maxValue: 'UA', maxLabel: 'Ukraine', integrityValue: 'Ukraine' },
      {
        maxValue: 'AE',
        maxLabel: 'United Arab Emirates (the)',
        integrityValue: 'United Arab Emirates',
      },
      {
        maxValue: 'GB',
        maxLabel: 'United Kingdom (the)',
        integrityValue: 'United Kingdom',
      },
      {
        maxValue: 'UM',
        maxLabel: 'United States Minor Outlying Islands (the)',
        integrityValue: 'United States Minor Outlying Islands',
      },
      { maxValue: 'UY', maxLabel: 'Uruguay', integrityValue: 'Uruguay' },
      { maxValue: 'UZ', maxLabel: 'Uzbekistan', integrityValue: 'Uzbekistan' },
      { maxValue: 'VU', maxLabel: 'Vanuatu', integrityValue: 'Vanuatu' },
      {
        maxValue: 'VE',
        maxLabel: 'Venezuela (Bolivarian Republic of)',
        integrityValue: 'Venezuela',
      },
      { maxValue: 'VN', maxLabel: 'Vietnam', integrityValue: 'Vietnam' },
      {
        maxValue: 'VG',
        maxLabel: 'Virgin Islands (British)',
        integrityValue: 'Virgin Islands',
      },
      {
        maxValue: 'WF',
        maxLabel: 'Wallis and Futuna',
        integrityValue: 'Wallis and Futuna',
      },
      {
        maxValue: 'EH',
        maxLabel: 'Western Sahara',
        integrityValue: 'Western Sahara',
      },
      { maxValue: 'YE', maxLabel: 'Yemen', integrityValue: 'Yemen' },
      { maxValue: 'ZM', maxLabel: 'Zambia', integrityValue: 'Zambia' },
      { maxValue: 'ZW', maxLabel: 'Zimbabwe', integrityValue: 'Zimbabwe' },
    ];
  }

  getReportItems(): Observable<ValueOptions> {
    const options = new ValueOptions();

    options.addOption('New Entrant Report', 'New Entrant');
    options.addOption('Annual Report', 'Annual');
    options.addOption('Termination Report', 'Termination');
    options.addOption('Annual/Termination Report', 'Annual/Termination');
    options.addOption('Periodic Transaction Report', 'Periodic Transaction');

    return of(options);
  }
  
  getReportStatuses(): Observable<ValueOptions> {
    const options = new ValueOptions();

    options.addOption('Not Started', 'Not Started');
    options.addOption('Draft, Pre-Review', 'Draft, Pre-Review');
    options.addOption('Draft, Under Review', 'Draft, Under Review');
    options.addOption('Draft, Pending Release', 'Draft, Pending Release');
    options.addOption('Draft, Pre-Cleared', 'Draft, Pre-Cleared');
    options.addOption('Draft, Update', 'Draft, Update');
    options.addOption('Report, Under Agency Review', 'Report, Under Agency Review');
    options.addOption('Report, Agency Certified', 'Report, Agency Certified');
    options.addOption('Report, Under OGE Review', 'Report, Under OGE Review');
    options.addOption('Report, Closed w/o Agency Certification', 'Report, Closed w/o Agency Certification');
    options.addOption('Report, OGE Certified', 'Report, OGE Certified');
    options.addOption('Report, Closed w/o OGE Certification', 'Report, Closed w/o OGE Certification');
    options.addOption('Data Imported', 'Data Imported');    

    return of(options);
  }

  getRulesVersions(): Observable<ValueOptions> {
    const options = new ValueOptions();

    options.addOption('0', 'Pre Jan 1, 2019');
    options.addOption('201901', 'Jan 1, 2019');
    options.addOption('201902', 'Feb 1, 2019');
    options.addOption('201911', 'Nov 11, 2019');
    options.addOption('202008', 'Aug 9, 2020');
    options.addOption('202010', 'Oct 27, 2020');
    options.addOption('202111', 'Nov 1, 2021');
    options.addOption('202306', 'Jun 9, 2023');
    options.addOption('202311', 'Nov 12, 2023');
    // OGE-8423 
    options.addOption('202402', 'Feb 09, 2024');

    /**
     * Form change 3 2024 - allow for configurable turn on
     * OGE-8372, OGE-8373, OGE-8604, OGE-8605, OGE-8640, OGE-8419
     */
    if (this.configDataService.enableFormChange3Option) {
      options.addOption('202408', 'Aug 05, 2024');
    }
    return of(options);
  }
}
