import { FilingTypeKey } from './filing-type-key.enum';
import { Group } from './group.model';
import { Workflow } from './workflow.model';

export enum RoleKey {
  AgencyAdministrator = 'AGENCY_ADMINISTRATOR',
  AgencyDAEO = 'AGENCY_DAEO',
  AgencyRecordsManager = 'AGENCY_RECORDS_MANAGER',
  CertifyingOfficial = 'CERTIFYING_OFFICIAL',
  EthicsOfficial = 'ETHICS_OFFICIAL',
  Filer = 'FILER',
  NomineeReviewer = 'NOMINEE_REVIEWER',
  OGEAdministrator = 'OGE_ADMINISTRATOR',
  OGECertifyingOfficial = 'OGE_CERTIFYING_OFFICIAL',
  OGEDirector = 'OGE_DIRECTOR',
  OGENomineeCertifyingOfficial = 'OGE_NOMINEE_CERTIFYING_OFFICIAL',
  OGENomineeProgramManager = 'OGE_NOMINEE_PROGRAM_MANAGER',
  OGENomineeRecordsManager = 'OGE_NOMINEE_RECORDS_MANAGER',
  OGENomineeReviewer = 'OGE_NOMINEE_REVIEWER',
  OGERecordsManager = 'OGE_RECORDS_MANAGER',
  OGEReviewer = 'OGE_REVIEWER',
  PointOfContact = 'POINT_OF_CONTACT',
  PPOLead = 'PPO_LEAD',
  PPOReviewer = 'PPO_REVIEWER',
  PTTLead = 'PTT_LEAD',
  PTTReviewer = 'PTT_REVIEWER',
  ReadOnlyReviewer = 'READ_ONLY_REVIEWER',
  RecordsManager = 'RECORDS_MANAGER',
  Router = 'ROUTER',
  Screener = 'ETHICS_SCREENING_REVIEWER',
  Supervisor = 'SUPERVISOR',
  SystemAdministrator = 'SYSTEM_ADMINISTRATOR',
  SystemAssistant = 'SYSTEM_ASSISTANT',
  SystemSuperuser = 'SYSTEM_SUPERUSER',
  WHCOLead = 'WHCO_LEAD',
  WHCOReviewer = 'WHCO_REVIEWER',
}

export enum RoleType {
  AgencyAdministrator = 'Agency Administrator',
  Filer = 'Filer',
  PointOfContact = 'Point of Contact',
  RecordsManager = 'Records Manager',
  Reviewer = 'Reviewer',
  SystemAdministrator = 'System Administrator',
}

export class RolesForFiling {
  isFiler: boolean = false;
  isReviewer: boolean = false;
  isOge: boolean = false;
  isDesignee: boolean = false;
  designeeFilerUserId: string = '';
  roles: Role[] = [];

  static fromJson(json: any): RolesForFiling {
    const rolesForFiling = new RolesForFiling();

    if ('isFiler' in json) rolesForFiling.isFiler = json.isFiler;
    if ('isReviewer' in json) rolesForFiling.isReviewer = json.isReviewer;
    if ('isOge' in json) rolesForFiling.isOge = json.isOge;
    if ('isDesignee' in json) rolesForFiling.isDesignee = json.isDesignee;
    if ('designeeFilerUserId' in json)
      rolesForFiling.designeeFilerUserId = json.designeeFilerUserId;
    if ('roles' in json) rolesForFiling.roles = json.roles;

    return rolesForFiling;
  }

  hasRole(roleKey: RoleKey): boolean {
    return this.roles.reduce((verdict: boolean, role: Role) => {
      return verdict || role.surrogateKey === roleKey;
    }, false);
  }
}

export enum AgencyType {
  PPO = 'PPO',
  PTT = 'PTT',
  WHCO = 'WHCO',
  OGEOversight = 'OGE-OVERSIGHT',
  Regular = 'REGULAR',
}

export class Role {
  surrogateKey: RoleKey;
  group: Group;
  type: RoleType;
  canCloseReports: boolean;
  allowAlternates: boolean;
  isOGERole: boolean;
  isPrimary: boolean;
  signature: string;
  label: string;
  authKey: string;
  agencyType: AgencyType;
  groupType: string;
  filingType?: FilingTypeKey;
  agencySurrogateKey: string;
  class: string;
  canReviewExtensionRequest: boolean;

  public static readonly nonFilingRoleKeys: RoleKey[] = [
    RoleKey.OGEAdministrator,
    RoleKey.OGEDirector,
    RoleKey.OGERecordsManager,
    RoleKey.SystemAdministrator,
    RoleKey.SystemSuperuser,
  ];

  constructor(name: string, type: string) {
    this.surrogateKey = Role.convertRoleKey(name);
    if (type) {
      this.type = Role.convertRoleType(type);
    }
  }

  static isRoleHolder(
    role: string,
    user: string,
    workflow: Workflow[]
  ): boolean {
    for (let i = 0; i < workflow.length; i++) {
      if (workflow[i].role?.surrogateKey === role) {
        for (let r = 0; r < workflow[i].holders.length; r++) {
          if (workflow[i].holders[r] === user) {
            return true;
          }
        }
        break;
      }
    }

    return false;
  }

  static fromJson(json: any): Role {
    const role = new Role(json.role_surrogate_key, json.role_type);
    role.group = new Group();
    role.group.name = 'group' in json ? json.group : '';
    role.group.surrogateKey = json.group_surrogate_key;
    role.canCloseReports =
      'can_close_reports' in json ? json.can_close_reports == 'true' : false;
    role.isOGERole = 'is_oge_role' in json ? json.is_oge_role : false;
    role.isPrimary = 'is_primary' in json ? json.is_primary : false;
    role.signature = 'signature' in json ? json.signature : '';
    role.label = 'label' in json ? json.label : '';
    role.authKey = json.auth_key;
    role.agencyType = json.agency_type as AgencyType;
    role.groupType = json.group_type;
    if (!!json.filing_type_surrogate_key) {
      switch (json.filing_type_surrogate_key) {
        case 'GENERAL_278':
          role.filingType = FilingTypeKey.General;
          break;
        case 'CONFIRMED_278':
          role.filingType = FilingTypeKey.Confirmed;
          break;
      }
    }
    role.agencySurrogateKey = json.agency_surrogate_key;
    role.canReviewExtensionRequest = 'can_review_extension_request' in json ? json.can_review_extension_request === 'true' : false;

    return role;
  }

  static fromJson2(json: any): Role {
    const role = new Role(json.surrogate_key, json.type);
    role.label = 'label' in json ? json.label : '';
    role.allowAlternates =
      'allow_alternates' in json ? json.allow_alternates == 'true' : false;
    role.class = 'class' in json ? json.class : '';
    role.canReviewExtensionRequest = 'can_review_extension_request' in json ? json.can_review_extension_request === 'true' : false;
    return role;
  }

  static mapDatabaseResult(result: any, arrToken: string = 'rows'): Role[] {   
    function convertBooleanStrings(roleJson: any) {
      roleJson.can_close_reports = roleJson.can_close_reports === 'true';
      roleJson.is_oge_role = roleJson.is_oge_role === 'true';
      roleJson.is_primary = roleJson.is_primary === 'true';
      return roleJson;
    }

    const roles: Role[] = [];
    if (!(result && result.results && arrToken in result.results)) {
      return roles;
    }
    if (result && result.results && arrToken in result.results && Array.isArray(result.results[arrToken])) {
      result.results[arrToken].forEach((roleJson: any) =>
        roles.push(Role.fromJson(convertBooleanStrings(roleJson)))
      );
    } else if (!!result.results) {
      roles.push(Role.fromJson(convertBooleanStrings(result.results)));
    }
    return roles;
  }

  public static getNonFilingRolesData(roles: Role[]): Role[] {
    return roles.filter((role: Role) => {
      return Role.nonFilingRoleKeys.includes(role.surrogateKey);
    });
  }

  // TODO
  public static isFiler(roles: Role[]): boolean {
    return false;
  }

  public static isDesignee(roles: Role[]): boolean {
    return false;
  }

  public static isReviewer(roles: Role[]): boolean {
    return false;
  }

  public static convertRoleKey(role: string): RoleKey {
    for (const entry of Object.entries(RoleKey)) {
      if (Array.isArray(entry) && entry.length > 1 && entry[1].toString().toUpperCase() === role.toUpperCase()) {
        return entry[1];
      }
    }
    throw new Error(`RoleKey '${role}' not recognized.`);
  }

  public static convertRoleType(type: string): RoleType {
    switch (type.toUpperCase()) {
      case 'AGENCY_ADMINISTRATOR': {
        return RoleType.AgencyAdministrator;
      }
      case 'POINT_OF_CONTACT': {
        return RoleType.PointOfContact;
      }
      case 'RECORDS_MANAGER': 
      case 'AGENCY_RECORDS_MANAGER': {        
        return RoleType.RecordsManager;
      }
      case 'SYSTEM_ADMINISTRATOR': {
        return RoleType.SystemAdministrator;
      }
      default: {
        for (const entry of Object.entries(RoleType)) {
          if (entry[1].toString().toUpperCase() === type.toUpperCase()) {
            return entry[1];
          }
        }
      }
    }

    throw new Error(`RoleType '${type}' not recognized.`);
  }

  public getSurrogateKey(): string {
    return this.surrogateKey;
  }

  public isSpecialAgencyType(): boolean {
    return this.agencyType == AgencyType.PPO ||
      this.agencyType == AgencyType.PTT ||
      this.agencyType == AgencyType.WHCO
      ? true
      : false;
  }
}
