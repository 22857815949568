import { ElementRef, Inject, Injectable, OnDestroy } from '@angular/core';
import {
  Filing,
  Filing278,
  Filing278T,
  FilingHelper,
} from '../models/filing.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from './config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  BehaviorSubject,
  forkJoin,
  iif,
  Observable,
  of,
  throwError,
  Timestamp,
  Subject,
} from 'rxjs';
import { map, mergeMap, tap, catchError, takeUntil } from 'rxjs/operators';
import { SessionService } from './session.service';
import { ReportStatus, Status } from '../models/status.model';
import { AgencyType, Role, RoleKey } from '../models/role.model';
import { SubWindowsManagerService } from './sub-windows-manager.service';
import { FormDataPersistenceService } from './form-data-persistence.service';
import { IntegrityPersistenceService } from './integrity-persistence.service';
import { DocumentsUtilitiesService } from './documents-utilities.service';
import { FilingIndicativeData } from '../models/filing-indicative-data.model';
import { QueryService } from './query.service';
import { APP_BASE_HREF } from '@angular/common';
import { UserService } from './user.service';
import { Comment } from '../models/comment.model';
import { SanitizeStringSpecialCharactersPipe } from '../pipes/sanitize-string-special-characters.pipe';
import { BusyTracker } from '../busy-tracker';
import { LogLevel, LogService } from './log.service';
import { Workflow } from '../models/workflow.model';
import _ from 'lodash-es';
import { Group } from '../models/group.model';
import { GroupAdmin } from '../../modules/admin/models/group-admin.model';
import { Agency } from '../models/agency.model';
import { NotAFilerModalComponent } from '../modals/not-a-filer-modal/not-a-filer-modal.component';
import {
  NomineeContext,
  NomineeWorkflow,
  NomineeWorkflowStep,
} from '../models/nominee-context.model';
import { UnsavedChangesModalComponent } from '../modals/unsaved-changes-modal/unsaved-changes-modal.component';
import { BroadcastService } from './broadcast.service';
import { ErrorModalComponent } from '../modals/error-modal/error-modal.component';
import { FormsTemplatePersistenceService } from './forms-template-persistence.service';
import { SessionCheckService } from './session-check.service';
import {ConfigDataService} from './config-data.service';
import {ValuesService} from './values.service';
import {ValueOptions} from '../models/value-options.model';

interface Fragment {
  _options: {
    propertiesToRemove: string[];
    createSkeletonGettingStartedDocIfNecessary: boolean;
  };
}

interface GettingStartedData {
  filing: Filing;
  indicativeData: any;
}

interface NomineeReportEvents {
  released: Timestamp<any> | null;
  firstSecondSignature: number | null;
  filerSecondSignature: number | null;
  precleared: number | null;
}

@Injectable({
  providedIn: 'root',
})
export class ReportUtilitiesService implements OnDestroy {
  protected destroyed$: Subject<boolean> = new Subject();
  public filingsSubject = new BehaviorSubject<Filing[]>([]);
  private saveDocumentObservables: { [x: string]: Observable<any>[] } = {};
  nav = new BehaviorSubject(false);
  filingOwnerId: string;

  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    private subWindows: SubWindowsManagerService,
    private formDataPersistenceService: FormDataPersistenceService,
    private integrityPersistenceService: IntegrityPersistenceService,
    private queryService: QueryService,
    private documentsUtilitiesService: DocumentsUtilitiesService,
    @Inject(APP_BASE_HREF) private baseHref: string,
    private userService: UserService,
    private modalService: NgbModal,
    private broadcastService: BroadcastService,
    private sessionCheckService: SessionCheckService,
    private configDataService: ConfigDataService,
    private valuesService: ValuesService,
  ) {
    this.filingOwnerId = this.sessionCheckService.isDesignee
      ? this.sessionCheckService.metaInfoRecord.results.filer_id
      : this.sessionService.getMaxUsername();
    
    window.addEventListener('storage', this.storageEventListener.bind(this));
    this.subWindows.message
      .pipe(takeUntil(this.destroyed$))
      .subscribe((message) => {
        if (
          message.origin == location.origin &&
          message.data.model == 'Filing' &&
          message.data.action == 'Update'
        ) {
          this.fetchMyTasksFilings(
            this.filingOwnerId,
            undefined,
            true
          );
        }
      });
  }

  rebuildNavigation() {
    this.nav.next(true);
  }

  isUserReportFiler(filing: Filing): boolean {
    return filing.userId == this.sessionService.getMaxUsername();
  }

  static isFilerInGroup(report: Filing, roles: Role[]): boolean {
    for (const role of roles) {
      const groupId =
        (report as Filing278)?.assigningGroupSurrogateKey ??
        report.group?.surrogateKey;

      if (
        role.group.surrogateKey === groupId &&
        role.surrogateKey === RoleKey.Filer
      ) {
        return true;
      }
    }

    return false;
  }

  static is278t(filing: Filing): boolean {
    return !!(
      (filing.type && filing.type === '278-T') ||
      (filing.item && filing.item.indexOf('278-T') === 0)
    );
  }

  static getIndicativeDataFormId(filing: Filing): string {
    return this.is278t(filing) ? '278_0_T_Cover_Page' : '2_1_My_Filing';
  }

  static isNomineeReport(filing: Filing): boolean {
    return filing.item === 'Nominee Report';
  }

  static isPasDaeo(filing: Filing): boolean {
    return filing.filingType === 'CONFIRMED_278';
  }

  static getUniqueListOfUsernames(
    filing: Filing,
    workflowArray: any[]
  ): string[] {
    const uniqueIds = new Set<string>();
    if (!_.isEmpty(filing.signatures)) {
      filing.signatures.forEach((signatureArray) => {
        if (_.isArray(signatureArray)) {
          signatureArray.forEach((signature) => uniqueIds.add(signature.by));
        }
      });
    }

    workflowArray.forEach((workflow) => {
      if (!_.isEmpty(workflow.holder)) {
        uniqueIds.add(workflow.holder);
      }
    });

    if (!!filing.assignedTo) {
      uniqueIds.add(filing.assignedTo);
    }

    if (filing instanceof Filing278) {
      const filing278: Filing278 = filing as Filing278;
      if (
        !!filing278.nomineeContext &&
        !!filing278.nomineeContext.assignedToAgencyRouter
      ) {
        uniqueIds.add(filing278.nomineeContext.assignedToAgencyRouter);
      }
    }

    return Array.from(uniqueIds);
  }

  public fetchMyTasksFilings(
    filerId: string,
    tracker?: BusyTracker,
    refresh = false
  ): void {
    if (refresh || this.filingsSubject.getValue().length === 0) {
      const sub = this.queryService
        .get(ConfigService.INTEGRITY_SERVICE_GET_MY_TASKS, {
          queryParams: { 
            filerId,
            sessionUserId: this.sessionService.getMaxUsername(),
          },
          dssGroupedByElement: 'data',
          dssRowName: 'filings',
        })
        .pipe(takeUntil(this.destroyed$))
        .subscribe((json: any[]) => {
          this.filings = json;
        });
      if (tracker) {
        tracker.add(sub);
      }
    }
  }

  saveReportData(origFiling: Filing): Observable<any> {
    const endpoint = ConfigService.INTEGRITY_SERVICE_GET_FILING.replace(
      '{filingid}',
      origFiling.filingId
    );
    return this.http
      .put<any>(
        endpoint,
        (origFiling.is278t()
          ? (origFiling as Filing278T)
          : (origFiling as Filing278)
        ).toJson(),
        this.getOptions()
      )
      .pipe(
        tap((result) => {
          if (result.status !== 0) {
            LogService.log(LogLevel.INFO, result.statusMessage);
            throw new Error(result.statusMessage);
          }
        }),
        map((data) => {
          // Convert from json and store in subject
          this.fetchMyTasksFilings(
            origFiling.userId,
            undefined,
            true
          );
          if (data.status === 0) {
            this.formDataPersistenceService.notifyParentWindowOfFilingChange();
            return Filing.createFromJson(data.results.values.values.data[0][1]);
          } else {
            return throwError(data.statusMessage);
          }
        })
      );
  }

  /**
   * First check for version mismatch. If ok, then proceed to saveWizardForm
   * OGE-8455
   */
  saveWizardFormData(
    filingId: string,
    formId: string,
    surrogateKey: string,
    formData: any
  ): Observable<any> {
    return this.validateWizardFormVersion(
      filingId,
      formId,
      surrogateKey,
      formData
    )
      .pipe(
        catchError((error) => {
          return throwError(error);
        }),
        mergeMap((isValid) => {
          return this.saveWizardForm(
            filingId,
            formId,
            surrogateKey,
            formData.toJson()
          ).pipe(
            tap((result) => {
              if (result.status === 0) {
              } else {
                LogService.log(LogLevel.INFO, result.statusMessage);
                throw new Error(result.statusMessage);
              }
              return result;
            }));
        })
      );
  }

  public validateWizardFormVersion(
    filingId: string,
    formId: string,
    surrogateKey: string,
    formData: any
  ): Observable<boolean> {

    if (!formData.version) {
      return of(true);
    }
    return this.getWizardFormValues(surrogateKey, formId)
      .pipe(
        map((retrievedValues: any) => {
          // No mismatch
          if (!retrievedValues || !retrievedValues.version || retrievedValues.version === formData.version) {
            return true;
          } else {
            LogService.log(LogLevel.INFO, 'Wizard data version mismatch');
            throw new Error('version-mismatch');
          }
        })
      );
  }

  getWizardFormValues(filingId: string, formId: string): Observable<any> {
    const endpoint =
      ConfigService.MAX_PAAS_FORM_DATA_RDBMS_PERSISTENCE_RETRIEVE +
      FormsTemplatePersistenceService.getFormTableName(formId) +
      '.surrogate_key/placeholder/' +
      filingId +
      '.json';
    return this.http
      .get<any>(endpoint, { headers: this.sessionService.getAuthHeader() })
      .pipe(
        map((data) => {
          let formValues: any = {};
          if (data.status === 0 && !!data.results.data) {
            formValues = Object.assign({}, JSON.parse(data.results.data), {version: data.results.version});
          }
          return formValues;
        })
      );
  }

  saveWizardForm(
    filingId: string,
    formId: string,
    surrogateKey: string,
    formData: any
  ): Observable<any> {
    const endpoint = ConfigService.INTEGRITY_SERVICE_SAVE_WIZARD_FORM.replace(
      '{filingId}',
      filingId
    )
      .replace('{formId}', formId)
      .replace('{surrogateKey}', surrogateKey);
    return this.http.post<any>(endpoint, formData, this.getOptions()).pipe(
      tap((data) => {
        if (data.status === 0) {
          return data;
        } else {
          throwError(data.errorMessage);
        }
      })
    );
  }

  createFiling(filing: Filing): Observable<Filing> {

    return this.http
      .post<any>(
        ConfigService.INTEGRITY_SERVICE_CREATE_FILING,
        filing.toJson(),
        this.getOptions()
      )
      .pipe(
        map((data) => {
          const report = Filing.createFromJson(
            data.results.values.values.data[0][1]
          );

          // These values aren't being returned in the json even though they are saved to the database.
          // Manually setting them so that the front end has the correct values instantly
          report.group.name = filing.group.name;
          report.group.agencyName = filing.group.agencyName;
          report.agency = filing.group.agencyName;
          
          this.fetchMyTasksFilings(
            filing.userId,
            undefined,
            true
          );
          return report;
        })
      );
  }

  getFiling(filingId: string): Observable<Filing> {
    return this.formDataPersistenceService.get('filing', filingId).pipe(
      map((data) => {
        if (data.status !== 0) {
          const msg = data.statusMessage && data.statusMessage.statusMessage
            ? data.statusMessage.statusMessage
            : data.statusMessage;
          throw new Error(
            `An error occurred while getting the report data. ${msg}`
          );
        }

        if (data.results.type === '278') {
          return Filing278.createFromJson(data.results);
        } else {
          return Filing278T.createFromJson(data.results);
        }
      })
    );
  }

  go(report: FilingHelper, roles: Role[], user: string) {
    if (
      !report.workflow.theEnd &&
      !ReportUtilitiesService.isFilerInGroup(report, roles)
    ) {
      this.showNotAFilerModal();
      return;
    }

    let sub: Observable<any>;

    // If the report is in "Not Started" status, update the status to "Draft"
    if (
      !report.workflow ||
      ReportStatus.NotStarted === report.workflow.status.status
    ) {
      const status = new Status(ReportStatus.Draft);
      status.workflowStep = 1;
      status.timeStamp = new Date().getTime();
      status.by = user;
      report.setCurrentStatus(status);

      report.workflowStep = 1;

      if (report.is278t() && !report.due) {
        const due = new Date();
        due.setDate(due.getDate() + 30);

        report.originalDue = due;
        report.due = due;
      }

      sub = this.saveReportData(report)
        .pipe(takeUntil(this.destroyed$));

    } else {
      sub = of('');
    }

    sub.subscribe(() => {
      this.subWindows.openSubWindow(`${this.baseHref}${report.url}`);
    });
  }

  showNotAFilerModal(): void {
    const modalRef = this.modalService.open(NotAFilerModalComponent, {
      scrollable: false,
      size: 'lg',
      centered: true,
    });
  }

  private getOptions() {
    return {
      headers: {
        // 'Authorization': this.sessionService.getCasKey(),
        'x-session-user-id': this.sessionService.getMaxUsername(),
      },
    };
  }

  /**
   * OGE-8651: Getting Started data no longer deleted on reset filing.
   * 
   * For previously-reset filings, dndicative data may be "status 50 not found" after a filing reset.
   * We need to treat this as a valid state for draft filings but not after submitted.
   */
  public getIndicativeData(report: Filing): Observable<any> {
    let indicativeDataFormId: string =
      ReportUtilitiesService.getIndicativeDataFormId(report);

    return this.formDataPersistenceService
      .get(indicativeDataFormId, report.filingId)
      .pipe(
        map((data: any) => {
          if (data.status !== 0) {
            const err = `GETTING STARTED/GENERAL INFORMATION: no 'getting started' data found for filing ID ${report.filingId}`;
            console.log(err);
            throw new Error(err);
          }
          
          const indicativeData = Object.assign({}, data.results.data, {version: data.results.version});
          return FilingIndicativeData.createNew(indicativeData);
        })
      );
  }

  public saveIndicativeData(
    indicativeData: FilingIndicativeData,
    report: Filing
  ): Observable<any> {
    const indicativeDataFormId =
      ReportUtilitiesService.getIndicativeDataFormId(report);
    
    return this.validateIndicativeDataVersion(report, indicativeData.version)
      .pipe(
        catchError((error) => {
          return throwError(error);
        }),
        mergeMap((isValid) => {
          return this.saveWizardForm(
            report.filingId,
            indicativeDataFormId,
            report.filingId,
            indicativeData.toJson()
          ).pipe(
            tap((result) => {
              if (result.status === 0) {
                this.formDataPersistenceService.notifyParentWindowOfFilingChange();
              } else {
                LogService.log(LogLevel.INFO, result.statusMessage);
                throw new Error(result.statusMessage);
              }
              return result;
            }));
        })
      );
  }

  public getGettingStartedReportData(filingId: string): Observable<any> {

    return this.getFiling(filingId).pipe(
      mergeMap((report: Filing278) => {
        const filing = report as Filing278;
        
        return forkJoin([
          this.getIndicativeData(filing).pipe(
            catchError((error) => {
              // no indicative data, return null
              return of(null);
            })
          ),
          filing.group.surrogateKey
            ? this.queryService.getGroupAndParentAgency(
              filing.group.surrogateKey
              )
            : of(null),
          this.queryService.getMinimalContactInfo(filing.userId),
          this.getJobDescriptionRequired(
            filing.assigningGroupSurrogateKey ?? filing.group.surrogateKey
          ),
          this.documentsUtilitiesService.getJobDescription(filingId),
          filing.isPrepopulated
            ? this.queryService.getInitialAppointmentDate(filing.filingId)
            : of(null)
        ]).pipe(takeUntil(this.destroyed$))
          .pipe(
          map((result: any) => {
            const indicativeData: FilingIndicativeData =
              FilingIndicativeData.createNew(filing);
            
            indicativeData.new = !result[0];
            if (!!result[0]) {
              indicativeData.updateFromJson(result[0]);
            }
            
            if (!!result[1]) {
              indicativeData.setGroupAndParentAgency(
                result[1]
              );
            }
            
            indicativeData.updateFromJson(result[2]);
            // Set job description fields
            indicativeData.updateFromJson({
              requireJobDescription: result[3],
              job_description: !!result[4]
                ? result[4]
                : null,
            });
            // Set initial appointment date if applicable
            if (!!result[5]) {
              indicativeData.updateFromJson(result[5]);
            }
            const gettingStartedData: GettingStartedData = {
              filing,
              indicativeData,
            };
            
            return gettingStartedData;
          }),
          catchError((error) => {
            console.log(error);
            return throwError(error);
          })
        );
      })
    );
  }

  public getJobDescriptionRequired(groupId: string): Observable<boolean> {
    return this.queryService
      .getAgencySetting(groupId, 'requireJobDescription')
      .pipe(takeUntil(this.destroyed$))
      .pipe(
        map((setting: any) => {
          return setting === 'Yes';
        })
      );
  }

  public isReadOnly(filingId: string) {
    return this.queryService.canUserEditFiling(filingId).pipe(
      map((canEdit: boolean) => {
        // If canEdit is true, then readOnly is false
        return !canEdit;
      })
    );
  }

  public getFilerFullName(report: Filing) {
    return this.userService.getFullNameByTimestamp(
      report.originalUserId || report.userId,
      report.certifyTime || new Date().getTime()
    );
  }

  public getReviewerForFiling(filing: Filing) {
    return this.queryService.getFilingWorkflow(filing.filingId).pipe(
      mergeMap((workflow) => {
        const workflowStep = filing.workflowStep > 2 ? filing.workflowStep : 2;
        const steps = workflow.filter((step) => {
          return (
            step.step === workflowStep &&
            !/OGE_.*/.test('' + step.role?.surrogateKey)
          );
        });

        const reviewer = steps.length ? steps[0].holder : null;
        if (reviewer) {
          return this.queryService.getUserBasics(reviewer);
        } else {
          return of(null);
        }
      })
    );
  }

  public getReportWorkflowStep(workflow: Workflow[], filing: Filing): Workflow {
    for (const workflowStep of workflow) {
      if (workflowStep.step == filing.workflowStep) {
        return workflowStep;
      }
    }
    return new Workflow(new Status());
  }

  public nodeAddComment(
    filingId: string,
    comment: Comment
  ): Observable<Filing> {
    if (!comment.by) {
      comment.by = this.sessionService.getMaxUsername();
    }

    var serviceEndpoint = ConfigService.INTEGRITY_SERVICE_ADD_COMMENT.replace(
      '{filingid}',
      filingId
    );

    return this.http
      .put<any>(serviceEndpoint, comment.toJson(), this.getOptions())
      .pipe(
        map((data: any) => {
          if (data.status != 0) {
            throwError(data.status.statusMessage);
          } else {
            // TODO: Notify parent window
            // FormDataPersistenceFactory.notifyParentWindowOfFilingChange();
            if (data.results && data.results.data) {
              const sanitizer: SanitizeStringSpecialCharactersPipe =
                new SanitizeStringSpecialCharactersPipe();
              data.results.data = sanitizer.transform(
                JSON.parse(data.results.data)
              );
            }
          }
          return Filing278.createFromJson(
            data.results.results.values.values.data[0][1]
          );
        })
      );
  }

  private storageEventListener(event: StorageEvent) {
    if (event.storageArea === localStorage && event.key === 'filings') {
      try {
        if (event.newValue != null) {
          const filingsJson: any[] = JSON.parse(event.newValue);
          const filings: Filing[] = filingsJson.map((json) =>
            Filing278.createFromJson(json)
          );
          this.filingsSubject.next(filings);
        }
      } catch (e) {}
    }
  }

  saveReportFragment(
    filingId: string,
    filing: Filing,
    fragmentKeys: string[],
    createSkeletonGettingStartedDocIfNecessary = false
  ): Observable<any> {
    // Create a fragment from the filing object using the provided list of fragmentKeys.
    const fragment = this.setFragmentProperties(fragmentKeys, filing.toJson(), createSkeletonGettingStartedDocIfNecessary);
    
    // Write this fragment after all previous write promises are resolved.
    const observables = this.getSaveDocumentPromises('filing', filingId);
    return iif(
      () => observables.length === 0,
      this.saveFilingFragment(filing, fragment),
      forkJoin(observables).pipe(
        catchError((error) => {
          return throwError(error);
        }),
        tap(() => this.saveFilingFragment(filing, fragment))
      )
    );
  }

  private saveFilingFragment(
    filing: Filing,
    fragment: Fragment
  ): Observable<any> {
    
     return this.validateFilingVersion(filing)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      )
      .pipe(
        mergeMap((isValid) => {
          return this.integrityPersistenceService
            .saveFilingFragment(filing.filingId, fragment);
        }));
  }

  getSaveDocumentPromises(
    formId: string,
    documentId: string,
    newObservable: Observable<any> | null = null
  ): Observable<any>[] {
    const key = formId + '-' + documentId;
    if (!this.saveDocumentObservables.hasOwnProperty(key)) {
      this.saveDocumentObservables[key] = [];
    }

    // Send a copy snapshot in time of the promises array back to the $q.all() in the calling function.
    const observables: Observable<any>[] =
      this.saveDocumentObservables[key] || [];

    // Add the promise for the calling function to the promises stack after snapshot generated.
    if (newObservable !== null) {
      this.saveDocumentObservables[key].push(newObservable);
    }

    return observables;
  }

  getClosestFilerWorkflowStepNumber(
    target: number,
    workflows: Workflow[]
  ): number {
    let closest = -1;

    for (const workflow of workflows) {
      if (
        workflow.role?.surrogateKey === RoleKey.Filer &&
        workflow.step < target
      ) {
        closest = workflow.step;
      }
    }

    return closest;
  }

  private getHeaders(): HttpHeaders {
    return this.sessionService
      .getAuthHeader()
      .append('x-session-user-id', this.sessionService.getMaxUsername());
  }

  get filings(): any[] {
    const filings = localStorage.getItem('filings');
    return filings != null ? JSON.parse(filings) : [];
  }

  set filings(data: any[]) {
    localStorage.setItem('filings', JSON.stringify(data));
    const filings = data.map((f) => {
      if (f.type == '278') {
        return Filing278.createFromJson(f);
      } else {
        return Filing278T.createFromJson(f);
      }
    });
    this.filingsSubject.next(filings);
  }

  addFiling(json: any) {
    const filings = this.filings;
    filings.push(json);
    this.filings = filings;
  }

  updateFiling(json: any) {
    let filings = this.filings;
    filings = filings.map((f) =>
      f.data.filingId === json.data.filingId ? json : f
    );
    this.filings = filings;
  }

  /**
   * Get filing workflow, signature and step events for a nominee filing
   */
  getNomineeReportEvents(filing: Filing): Observable<Workflow[] | undefined> {
    let workflow: Workflow[] | undefined = undefined;

    if (!ReportUtilitiesService.isNomineeReport(filing)) {
      return of(workflow);
    }

    return this.queryService.getFilingWorkflow(filing.filingId).pipe(
      map((workflow) => {
        return workflow;
      })
    );
  }

  /**
   * If the report has a prepopulated report with data and the user is allowed to view it,
   * then download it. Otherwise return false.
   */
  downloadComparePriorReport(filing: Filing, formElement: ElementRef): void {
    this.tryDownloadComparePriorReport(filing, formElement)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((downloaded) => {
        if (!downloaded) {
          alert('Comparison report is not available.');
        }
      });
  }

  tryDownloadComparePriorReport(filing: Filing, formElement: ElementRef): Observable<boolean> {
    if (filing.prepopulated === 'no') {
      return of(false);
    }

    return this.queryService.canUserDownloadFiling(filing.prepopulated).pipe(
      map((canDownload) => {
        if (canDownload) {
          formElement.nativeElement.setAttribute('action', formElement.nativeElement.getAttribute('action')
            .replace(/{filingId}/, filing.prepopulated));
          this.downloadPdf(filing.prepopulated, formElement);
        }
        return canDownload;
      })
    );
  }
  
  downloadPdf(filingId: string, formElement: ElementRef): void {
    this.sessionService.setUploadAuthCookie();
    formElement.nativeElement.submit();
    setTimeout(() => this.sessionService.deleteUploadAuthCookie(), 500);
  }

  exportPdf(filingId: string) {
    const url = ConfigService.INTEGRITY_SERVICE_FILING + '/' + filingId + '/export/pdf';

    this.sessionService.setUploadAuthCookie();
    window.open(url);
    setTimeout(() => this.sessionService.deleteUploadAuthCookie(), 500);
  }

  getGridRowComments(
    rowId: string,
    gridType: string,
    deserialize?: (row: any) => any
  ): Observable<any> {
    const options = {
      params: {
        rowid: rowId,
        gridkey: gridType,
      },
      headers: this.sessionService.getNodeHeader(),
    };
    return this.http
      .get<any>(ConfigService.INTEGRITY_SERVICE_GET_GRID_COMMENTS, options)
      .pipe(
        map((data) => {
          if (!('rows' in data.comments)) {
            return [];
          }
          if (!!deserialize) {
            const rows: any[] = [];
            data.comments.rows.forEach((row: any) =>
              rows.push(deserialize(row))
            );
            return rows;
          }
          return data.comments.rows;
        })
      );
  }

  /**
   * Check if filer assignment (from Bulk Assign or Manage Assigned Reports) already exists.
   * ~ lookupReportByAgencyUserYearItemType
   *
   * EFEDS-6465 - disabled warning checks - code not verified working in v2
   */
  filerAssignmentLookup(params: any): Observable<any> {
    return this.queryService
      .filerAssignmentLookup(params)
      .pipe(map((data) => {}));
  }

  /**
   * For Annual and Termination filings are due on 5/15 unless that date falls on Saturday or Sunday. In that case, the due date
   * should be the following Monday.
   *
   * EFEDS-6435
   */
  getReportDueMonthAndDay(year: string): string {
    let monthAndDay = '5/15/';
    const date = new Date(monthAndDay + year);

    while (date.getDay() == 6 || date.getDay() == 0) {
      date.setDate(date.getDate() + 1);
    }

    // We know the month will always be 5 so pad left with 0. The date part will always be 2 digits.
    monthAndDay = '0' + (date.getMonth() + 1) + '/' + date.getDate() + '/';

    return monthAndDay;
  }

  createSkeletonGettingStartedDocIfNecessary(
    filing: Filing
  ): Observable<FilingIndicativeData> {
    return this.getIndicativeData(filing).pipe(
      catchError((_) => {
        const newData: FilingIndicativeData = FilingIndicativeData.createNew({
          filingId: filing.filingId,
          report_positionTitle: filing.position,
        });
        return this.saveIndicativeData(newData, filing).pipe(
          map((_) => newData)
        );
      })
    );
  }

  getNomineeWorkflowName(
    filing: Filing,
    assigningAgencyType: string
  ): Observable<string> {
    if (filing.group.surrogateKey) {
      // Read the PAS/DAEO workflow used by the agency to construct the workflow name
      return this.queryService
        .getGroupAndParentAgency(filing.group.surrogateKey)
        .pipe(
          map(
            (data) =>
              'NOMINEE_' +
              assigningAgencyType +
              '_' +
              data.agency.workflowTypes.confirmed278
          )
        );
    } else {
      // Use a default workflow name. All nominee workflows for an agency type (PPO,PTT,WHCO) are the same up
      // to the point of releasing to oge/agency
      return of(
        'NOMINEE_' +
          assigningAgencyType +
          '_CONFIRMED_FILER_TO_CERTIFYING_OFFICIAL'
      );
    }
  }

  preCalculateNomineeWorkflow(
    filing: Filing278 | Filing278T,
    assigningAgency: GroupAdmin
  ): Observable<NomineeContext> {
    let _workflowName: string;

    return this.getNomineeWorkflowName(filing, assigningAgency.agencyType).pipe(
      map((workflowName) => {
        const nomineeContext: NomineeContext = new NomineeContext();
        nomineeContext.workflow = NomineeWorkflow.createFromJson({
          name: workflowName,
        });
        return nomineeContext;
      })
    );
  }

  public getDisplayIntermediateReviewersSetting(
    filing: Filing
  ): Observable<boolean> {
    return this.queryService
      .getAgencySetting(
        filing.group.surrogateKey,
        'displayIntermediateReviewers'
      )
      .pipe(map((setting) => setting == 'Yes'));
  }

  canDeactivate(isDirty: boolean): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (isDirty) {
        const modalRef = this.modalService.open(UnsavedChangesModalComponent, {
          backdrop: 'static',
          size: 'md',
        });
        modalRef.componentInstance.scope = 'page';
        modalRef.result
          .then((result) => {
            if (result) {
              resolve(result);
            }
          })
          .catch((e) => {});
      } else {
        resolve(true);
      }
    });
  }

  public saveIndicativeDataFragment(
    indicativeData: FilingIndicativeData,
    report: Filing,
    fragmentKeys: Array<string>,
    updateFiling = true
  ): Observable<any> {
    const indicativeDataFormId =
      ReportUtilitiesService.getIndicativeDataFormId(report);
    
    return this.validateIndicativeDataVersion(report, indicativeData.version)
      .pipe(
        catchError((error) => {
          return throwError(error);
        }),
        mergeMap((isValid) => {
          return this.updateIndicativeDataFromFragment(
            report,
            indicativeDataFormId,
            report.filingId,
            indicativeData.toJson(),
            fragmentKeys,
            updateFiling
          ).pipe(
            catchError((error) => {
              return throwError(error);
            }),
            map((data: any) => {
              this.formDataPersistenceService.notifyParentWindowOfFilingChange();
              return data;
            }),
          );
        })
      );
  }

  updateIndicativeDataFromFragment(
    filing: Filing,
    formId: string,
    surrogateKey: string,
    formData: any,
    fragmentKeys: Array<string>,
    updateFiling = true
  ): Observable<any> {
    const endpoint = ConfigService.INTEGRITY_SERVICE_SAVE_INDICATIVE_DATA_FRAGMENT.replace(
      '{filingId}',
      filing.filingId
    )
      .replace('{formId}', formId)
      .replace('{surrogateKey}', surrogateKey)
      .replace('{updatefiling}', updateFiling ? 'true' : 'false');

    const fragment = this.setFragmentProperties(fragmentKeys, formData, false);
    const options = {
      params: {
        formId,
        surrogateKey,
        filingSurrogateKey: filing.filingId,
        updateFiling: updateFiling ? 'true' : 'false'
      },
      headers: this.getHeaders(),
    };

    const errorMessage = `updateIndicativeDataFromFragment failed. Status != 0 {{status}}. URL= ${endpoint}, params=`;
    return this.http.put<any>(endpoint, fragment, options).pipe(
      tap({
        next: (data: any) => {
          if (data.status !== 0) {
            LogService.log(
              'error',
              errorMessage.replace(/{{status}}/, data.status)
            );
          }
        },
        error: error => {
          LogService.log('error', errorMessage.replace(/{{status}}/, error.status));
          throw new Error('filing-update');
        }
      })
    );
  }

  setFragmentProperties(fragmentKeys: Array<string>, dataJson: any, createSkeletonGettingStartedDocIfNecessary: boolean = false): any {
    const fragment: Fragment = {
      _options: {
        propertiesToRemove: [],
        createSkeletonGettingStartedDocIfNecessary,
      },
    };

    if (!!fragmentKeys) {
      fragmentKeys.forEach((key) => {
        if (dataJson.hasOwnProperty(key)) {
          fragment[key] = dataJson[key];
        } else {
          fragment._options.propertiesToRemove.push(key);
        }
      });
    }
    return fragment;
  }

  public filerSignFiling(
    filing: Filing,
    requestData: string
  ): Observable<any> {
    
    return this.validateFilingVersion(filing)
    .pipe(
      catchError((error) => {
        return throwError(error);
      }),
      mergeMap((isValid) => {
        return this.integrityPersistenceService.filerSignFiling(filing.filingId, requestData);
      })
    );
  }
  
  public resetFiling(filing: Filing, indicativeData: FilingIndicativeData): Observable<any> {
    return this.validateFilingVersion(filing)
      .pipe(
        catchError((error) => {
          return throwError(error);
        }),
        mergeMap((isValid) => {
          return this.integrityPersistenceService
            .resetFiling(filing.filingId);
        }))
      .pipe(
        tap((data) => {
          if (data && data.hasOwnProperty('status') && data.status !== 0) {
            throw new Error('reset-filing');
          }
        })
      );
  }

  public validateFilingVersion(loadedFiling: Filing
  ): Observable<any> {
    
    return this.getFiling(loadedFiling.filingId)
      .pipe(
        map((filing) => {
          // OGE-3847: Skip the version check for updates outside of the filing workflow like My Queue Reassign
          if (!loadedFiling.version || filing.version === loadedFiling.version) {
              return true;
          }
          else {
            LogService.log(LogLevel.INFO, 'filing version mismatch');
            throw new Error('version-mismatch');
          }
        })
      );
  }

  /**
   * If we don't have indicative data because the filing was reset and not prepopulated,
   * return true.
   */
  public validateIndicativeDataVersion(
    filing: Filing,
    version?: string
  ): Observable<boolean> {

    return this.getIndicativeData(filing)
      .pipe(
        catchError((error) => {
          // no indicative data, return with new flag
          const indicativeData = FilingIndicativeData.createNew(filing);
          indicativeData.new = true;
          return of(indicativeData);
        }))
      .pipe(
        map((indicativeData: any) => {
          if (indicativeData.new || !indicativeData.version || !version || indicativeData.version === version) {
            return true;
          } else {
            LogService.log(LogLevel.INFO, 'Indicative data version mismatch');
            throw new Error('version-mismatch');
          }
        })
      );
  }

  saveExtensionRequest(
    requestData: any,
  ): Observable<any> {

    const endpoint = ConfigService.INTEGRITY_SERVICE_SAVE_EXTENSION_REQUEST.replace
    (
      '{filingId}',
      requestData.filingId
    );

    requestData.sessionUserId = this.sessionService.getMaxUsername();

    const config = {
      params: {},
      headers: { 'x-session-user-id': this.sessionService.getMaxUsername() },
    };

    return this.http
      .post(
        endpoint,
        requestData,
        config
      )
      .pipe(
        catchError((error) => {
          throw new Error('save-extension-request');
        }),
        map((data: any) => {
          if (data.status === 0) {
            this.fetchMyTasksFilings(
              this.filingOwnerId,
                undefined,
              true
              );
            return data;
          } else {
            LogService.log(LogLevel.INFO, 'Failed to save filing extension');
            throw new Error('save-extension-request');
          }
        })
      );
  }

  updateExtensionRequest(
    requestData: any
  ): Observable<any> {

    const endpoint = ConfigService.INTEGRITY_SERVICE_UPDATE_EXTENSION_REQUEST.replace
    (
      '{filingId}',
      requestData.filingId
    ).replace(
      '{requestId}',
      requestData.id
    );

    requestData.sessionUserId = this.sessionService.getMaxUsername();

    const config = {
      params: {
      },
      headers: { 'x-session-user-id': this.sessionService.getMaxUsername() },
    };
    
    return this.http
      .put(
        endpoint,
        requestData,
        config
      )
      .pipe(
        catchError((error) => {
          throw new Error('update-extension-request');
        }),
        map((data: any) => {
          if (data.status === 0) {
            this.broadcastService.broadcast('filing-updated', {filingId: requestData.filingId, filingExtension: requestData});
            this.notifyChildWindowsOfFilingChange(requestData.filingId);
            return data;
          } else {
            LogService.log(LogLevel.INFO, 'Failed to save filing extension');
            throw new Error('update-extension-request');
          }
        })
      );
  }

  withdrawExtensionRequest(
    requestData: any
  ): Observable<any> {

    const endpoint = ConfigService.INTEGRITY_SERVICE_WITHDRAW_EXTENSION_REQUEST.replace
    (
      '{filingId}',
      requestData.filingId
    ).replace(
      '{requestId}',
      requestData.id
    );

    requestData.sessionUserId = this.sessionService.getMaxUsername();

    const config = {
      params: {
      },
      headers: { 'x-session-user-id': this.sessionService.getMaxUsername() },
    };

    return this.http
      .put(
        endpoint,
        requestData,
        config
      )
      .pipe(
        catchError((error) => {
          throw new Error('update-extension-request');
        }),
        map((data: any) => {
          if (data.status === 0) {
            this.fetchMyTasksFilings(
              this.filingOwnerId,
              undefined,
              true
            );
            return data;
          } else {
            LogService.log(LogLevel.INFO, 'Failed to withdraw filing extension');
            throw new Error('update-extension-request');
          }
        })
      );
  }
  
  saveExtensionGrant(
    requestData: any,
  ): Observable<any> {

    let endpoint = ConfigService.INTEGRITY_SERVICE_SAVE_EXTENSION_GRANT.replace
    (
      '{filingId}',
      requestData.filingId
    );
    
    const isNew = requestData.new;
    delete requestData.new;
    const errCode = (isNew) ? 'save' : 'update';
    if (!isNew) {
      endpoint = ConfigService.INTEGRITY_SERVICE_UPDATE_EXTENSION_GRANT
        .replace
        (
          '{filingId}',
          requestData.filingId
        )
        .replace(
          '{extensionId}',
          requestData.id
        );
    }    

    requestData.sessionUserId = this.sessionService.getMaxUsername();

    const config = {
      params: {},
      headers: { 'x-session-user-id': this.sessionService.getMaxUsername() },
    };

    const sub = (isNew) ? 
     this.http
      .post(
        endpoint,
        requestData,
        config
      )
      : this.http
        .put(
          endpoint,
          requestData,
          config
        ); 
       
    return sub
      .pipe(
        catchError((error) => {
          throw new Error(`${errCode}-extension-grant`);
        }),
        map((data: any) => {
          if (data.status === 0) {
            this.broadcastService.broadcast('filing-updated', {filingId: requestData.filingId, filingExtension: requestData });
            this.notifyChildWindowsOfFilingChange(requestData.filingId);
            return data;
          } else {
            LogService.log(LogLevel.INFO, `Failed to ${errCode} filing extension grant`);
            throw new Error(`${errCode}-extension-grant`);
          }
        })
      );
  }

  notifyChildWindowsOfFilingChange(filingId: string) {
    this.subWindows.getOpenSubWindows().forEach((subwindow) => {
      subwindow.postMessage(
          {
            model: 'Filing',
            action: 'Update',
            id: filingId,
          },
          location.origin
        );
      });
  }

  /**
   * OGE-2571 copy the job description, if any, from the source filing
   */
  copyJobDescription(newFilingId: string): Observable<boolean> {
    const endpoint = ConfigService.INTEGRITY_SERVICE_COPY_JOB_DESCRIPTION
      .replace
      (
        '{filingId}',
         newFilingId
      );

    const requestData = {
      sessionUserId: this.sessionService.getMaxUsername(),
    };

    const config = {
      params: {
      },
      headers: { 'x-session-user-id': this.sessionService.getMaxUsername() },
    };

    return this.http
      .put(
        endpoint,
        requestData,
        config
      )
      .pipe(
        catchError((error) => {
          console.log(error);
          throw new Error('copy-job-description');
        }),
        map((data: any) => {
          if (data.status === 0) {
            return true;
          } else {
            LogService.log(LogLevel.INFO, `Failed to copy job description from source filing`);
            throw new Error('copy-job-description');
          }
        })
      );
  }

  /**
   * e can be a code string that maps to a message in the compoent or it can be
   * the entire message itself.
   */
  showError(e: string) {
    const modalRef = this.modalService.open(ErrorModalComponent, {
      backdrop: 'static',
    });
    modalRef.componentInstance.title = 'Error';
    modalRef.componentInstance.error = e;
   
    modalRef.result.then((result) => {}).catch(() => {});
  }

  /**
   * Get the value of the most recent rules version
   */
  getLatestRulesVersion(): Observable<number> {
    return this.valuesService.getRulesVersions()
      .pipe(
        map((options: ValueOptions) => {
          const opt = options.asArray;
          const latest = opt.reduce((prev, current) => {
            return (prev.value > current.value) ? prev : current;
          }).value as string;
          return parseInt(latest, 10);
        })
      );
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
