<ng-template #content>
  <li routerLinkActive="active">
    <a href="" (click)="$event.preventDefault()">
      <i class="fas fa-inbox"></i>
      <span class="title">MY QUEUE</span>
    </a>
    <ul class="sub-menu">
      <li routerLinkActive="active">
        <a routerLink="/reviewer/my-queue"> My Queue </a>
      </li>
    </ul>
  </li>
  <li
    routerLinkActive="active"
    *ngIf="authorizationService.canAccessRecordsManagement()"
  >
    <a href="" (click)="$event.preventDefault()">
      <i class="fas fa-list"></i>
      <span class="title">RECORDS MANAGEMENT</span>
    </a>
    <ul class="sub-menu">
      <li routerLinkActive="active">
        <a routerLink="/reviewer/records-management"> Records Management </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/reviewer/bulk-actions"> Bulk Actions </a>
      </li>
    </ul>
  </li>
  <li routerLinkActive="active">
    <a href="" (click)="$event.preventDefault()">
      <i class="fas fa-search"></i>
      <span class="title">SEARCH</span>
    </a>
    <ul class="sub-menu">
      <li routerLinkActive="active">
        <a routerLink="/reviewer/search"> Search </a>
      </li>
    </ul>
  </li>
  <li routerLinkActive="active">
    <a href="" (click)="$event.preventDefault()">
      <i class="fas fa-cog"></i>
      <span class="title">SETTINGS</span>
    </a>
    <ul class="sub-menu">
      <li routerLinkActive="active">
        <a routerLink="/reviewer/contact-info"> My Contact Info </a>
      </li>
    </ul>
  </li>
  <li routerLinkActive="active">
    <a href="" (click)="$event.preventDefault()">
      <i class="fas fa-question-circle"></i>
      <span class="title">HELP</span>
    </a>
    <ul class="sub-menu">
      <li>
        <a
          *ngIf="!!menuItemsService.userResourcesDocument"
          [routerLink]="[]"
          (click)="menuItemsService.download(menuItemsService.userResourcesDocument)"
        >Integrity User Resources
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/reviewer/help/agency-support"> Agency Support </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/reviewer/help/form278e-instructions"> 278e Instructions </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/reviewer/help/form278t-instructions"> 278-T Instructions </a>
      </li>
      <li>
        <a
          (click)="
            window.open('https://www.oge.gov/Web/278eGuide.nsf', '_blank')
          "
        >
          Public Financial Disclosure Guide
        </a>
      </li>
    </ul>
  </li>
  <li>
    <a routerLink="/logout">
      <i class="fas fa-sign-out-alt"></i>
      <span class="title">LOG OUT</span>
    </a>
  </li>
</ng-template>
