import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { PermissionsService } from '../../../modules/admin/manage-agencies-and-groups/permissions.service';
import { AuthorizationService } from '../../services/authorization.service';
import { MenuItemsService } from '../menu-items.service';

@Component({
  host: { style: 'display: none' },
  selector: 'integrity-admin-menu-items',
  templateUrl: './admin-menu-items.component.html',
  styleUrls: ['./admin-menu-items.component.scss'],
  providers: [MenuItemsService],
})
export class AdminMenuItemsComponent implements OnInit {
  // This template/content stuff is used to move the content outside of the
  // <integrity-admin-menu-items> element in the html. This is needed
  // because the css is set up to expect the menu ul/li/a elements directly
  // inside each other.
  // Taken from: https://github.com/angular/angular/issues/18877
  @ViewChild('content', { static: true }) content: TemplateRef<{}>;
  canManageAgencies = false;
  public window: Window = window;

  constructor(
    private readonly viewContainer: ViewContainerRef,
    public authorizationService: AuthorizationService,
    private manageAgenciesAndGroupsPermissionsService: PermissionsService,
    public menuItemsService: MenuItemsService,
  ) {}

  ngOnInit(): void {
    this.viewContainer.createEmbeddedView(this.content);

    this.canManageAgencies =
      this.manageAgenciesAndGroupsPermissionsService.canUserManageAgencies();

    this.menuItemsService.init();
  }
}
