import { HttpClient } from '@angular/common/http';
import { ViewContainerRef } from '@angular/core';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NavigationStart, Router, Event } from '@angular/router';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { mergeMap, tap, map } from 'rxjs/operators';
import { Filing } from '../../models/filing.model';
import { MenuItem, MenuItems } from '../../models/menu-items.model';
import { Role, RoleKey, RoleType } from '../../models/role.model';
import { Workflow } from '../../models/workflow.model';
import { FilingService } from '../../services/filing.service';
import { FormDataPersistenceService } from '../../services/form-data-persistence.service';
import { IntegrityPersistenceService } from '../../services/integrity-persistence.service';
import { ReportUtilitiesService } from '../../services/report-utilities.service';
import { SessionCheckService } from '../../services/session-check.service';
import { SessionService } from '../../services/session.service';
import { QueryService } from '../../services/query.service';
import { BaseFilingMenuItemsComponent } from '../base-filing-menu-items.component';
import { MenuCompareFlags } from '../../models/menu-compare-flags.model';
import { SubWindowsManagerService } from '../../services/sub-windows-manager.service';
import { ConfigService } from '../../services/config.service';
import { MenuItemsService } from '../menu-items.service';

@Component({
  host: { style: 'display: none' },
  selector: 'integrity-review-filing-menu-items',
  templateUrl: './review-filing-menu-items.component.html',
  styleUrls: ['./review-filing-menu-items.component.scss'],
  providers: [MenuItemsService],
})
export class ReviewFilingMenuItemsComponent
  extends BaseFilingMenuItemsComponent
  implements OnInit
{
  // This template/content stuff is used to move the content outside of the
  // <integrity-review-filing-menu-items> element in the html. This is needed
  // because the css is set up to expect the menu ul/li/a elements directly
  // inside each other.
  // Taken from: https://github.com/angular/angular/issues/18877
  @ViewChild('content', { static: true }) content: TemplateRef<{}>;
  @ViewChild('downloadComparePriorReportForm') downloadComparePriorReportFormElement;

  currentWorkflowStep: Workflow;
  workflowRoles: Role[];
  nonWorkflowRoles: Role[];
  allFilingRoles: Role[];
  flags: MenuCompareFlags;
  downloadComparePriorReportUrl = ConfigService.INTEGRITY_SERVICE_FILING_DOWNLOAD;

  hideRoleKeys = [
    RoleKey.PPOLead,
    RoleKey.PPOReviewer,
    RoleKey.PTTLead,
    RoleKey.PTTReviewer,
    RoleKey.WHCOLead,
    RoleKey.WHCOReviewer,
  ];

  constructor(
    viewContainer: ViewContainerRef,
    filingService: FilingService,
    reportUtilitiesService: ReportUtilitiesService,
    router: Router,
    integrityPersistenceService: IntegrityPersistenceService,
    private sessionService: SessionService,
    sessionCheckService: SessionCheckService,
    formDataPersistenceService: FormDataPersistenceService,
    http: HttpClient,
    queryService: QueryService,
    public subWindows: SubWindowsManagerService,
    public menuItemsService: MenuItemsService,
  ) {
    super(
      viewContainer,
      filingService,
      reportUtilitiesService,
      router,
      integrityPersistenceService,
      sessionCheckService,
      formDataPersistenceService,
      http,
      queryService,
      subWindows
    );
    this.checkHighwater = false;
  }

  ngOnInit(): void {
    this.viewContainer.createEmbeddedView(this.content);

    this.init('assets/json/menu-review-filing.json');

    this.reportUtilitiesService.nav.subscribe(() => {
      if (this.filing?.filingId) {
        this.updateMenu(this.filing?.filingId);
      }
    });
    this.menuItemsService.init();
  }

  pullAdditionalData(): { [key: string]: Observable<any> } {
    const observables: { [key: string]: Observable<any> } = {};

    observables['roles'] = this.queryService
      .getFilingWorkflow(this.filing.filingId)
      .pipe(
        mergeMap((workflow) => {
          this.currentWorkflowStep = workflow[this.filing.workflowStep];

          return this.filingService.getUserRolesForFiling(
            this.sessionService.getMaxUsername(),
            this.filing.filingId
          );
        })
      )
      .pipe(
        mergeMap((workflowRoles) => {
          this.workflowRoles = workflowRoles;

          return this.filingService.getNonWorkflowUserRolesForFiling(
            this.sessionService.getMaxUsername(),
            this.filing.filingId
          );
        })
      )
      .pipe(
        map((nonWorkflowRoles: Role[]) => {
          this.nonWorkflowRoles = nonWorkflowRoles;

          this.allFilingRoles = this.workflowRoles.concat(
            this.nonWorkflowRoles
          );

          return null;
        })
      );

    observables['flags'] = this.queryService
      .getCompareFlags(this.filing.filingId)
      .pipe(map((flags) => (this.flags = flags)));

    // Flag function boolean values for menu checkmark icons
    observables['nonZeroDocuments'] = this.queryService
      .getFilingDocuments(this.filing.filingId)
      .pipe(map((documents) => documents.length > 0));
    // TODO
    observables['compareIsAvailable'] = of(false);
    // TODO
    observables['hasMemo'] = of(false);

    return observables;
  }

  customizeMenuItems() {
    const isFiler = this.sessionService.getMaxUsername() == this.filing.userId;

    // should this be here versus inside the MenuItems class itself?
    this.menuItems.items.forEach((item) => {
      if (
        item.isEnabled &&
        item.allowedRoleTypes &&
        item.allowedRoleTypes.length
      ) {
        item.isEnabled = this.allFilingRoles.reduce((allowed, role) => {
          return allowed || item.isAllowedRoleType(role.type);
        }, false);
      }

      item.items.forEach((subItem) => {
        if (
          subItem.isEnabled &&
          subItem.allowedRoleTypes &&
          subItem.allowedRoleTypes.length
        ) {
          subItem.isEnabled = this.allFilingRoles.reduce((allowed, role) => {
            return allowed || subItem.isAllowedRoleType(role.type);
          }, false);

          // Special Case: If this user is a filer and reviewer for this filing, disallow reviewer version of the "Submit Report" link.
          if (
            subItem.title == 'Submit Report' &&
            subItem.isAllowedRoleType(RoleType.Reviewer)
          ) {
            var isFiler = this.allFilingRoles.some((role) => {
              return role.type == RoleType.Filer;
            });

            if (isFiler) {
              subItem.isEnabled = false;
            }
          }
        }
      });
    });

    const matches = this.allFilingRoles.filter((role) => {
      return this.hideRoleKeys.includes(role.surrogateKey);
    });

    const nonMatches = this.workflowRoles.filter((role: Role) => {
      return !this.hideRoleKeys.includes(role.surrogateKey);
    });

    // EFEDS-6033 Agency Nominee Reviewer is not technically in the workflow, so check it separately
    nonMatches.push(
      ...this.allFilingRoles.filter((role) => {
        return (
          role.surrogateKey == RoleKey.NomineeReviewer &&
          role.group.surrogateKey == this.filing.group.surrogateKey
        );
      })
    );

    /*
    Rules for showing Special Agency View
    - Has only Special Agency role(s), or
    - Has Special Agency role(s) AND a non-special agency role AND report status is Draft, Pending Release
    */
    if (
      (matches.length > 0 && nonMatches.length == 0 && !isFiler) ||
      (matches.length > 0 &&
        nonMatches.length > 0 &&
        this.currentWorkflowStep.nomineePrerelease &&
        !isFiler)
    ) {
      this.menuItems.disable('MEMO');
      this.menuItems.disable('General Comments');
      this.menuItems.disable("Filer's Federal Positions");
      this.menuItems.disable("Filer's Positions");
      this.menuItems.disable("Filer's Employment-Related Assets and Income");
      this.menuItems.disable("Filer's Employment-Related Arrangements");
      this.menuItems.disable("Filer's Sources of Compensation");
      this.menuItems.disable("Spouse's Employment Related Assets and Income");
      this.menuItems.disable('Other Assets and Income');
      this.menuItems.disable('Transactions');
      this.menuItems.disable('Liabilities');
      this.menuItems.disable('Gifts and Reimbursements');

      // PPO/WHCO/PTT never see the Submit Report link, only the Release Report link (unless they are the filer)
      this.menuItems.disable('Submit Report');
    } else {
      // Non PPO/WHCO/PTT roles never see the Release Report link, only the Submit Report link (also applies to PPO/WHCO/PTT users who are the filer)
      this.menuItems.disable('Release Report');
    }
    this.setFlags();
  }

  /**
   * aka flag MenuNavigationController line 451
   * Handling needed flagFunctions:
   * nonZeroDocuments
   * compareIsAvailable
   * hasMemo:
   */
  setFlags() {
    this.menuItems.items.forEach((item: MenuItem) => {
      if (item.flagFunction) {
        switch (item.flagFunction) {
          case 'compareIsAvailable':
            this.checkCompareIsAvailable(item);
            break;
          case 'hasMemo':
            this.hasMemo(item);
            break;
          case 'nonZeroDocuments':
            this.nonZeroDocuments(item);
            break;

          default:
            console.log('--> Unhandled flagFunction ' + item.flagFunction);
        }
      }
    });
  }

  nonZeroDocuments(item: MenuItem) {
    this.queryService
      .getFilingDocuments(this.filing.filingId)
      .subscribe((docs) => {
        if (docs && docs.length > 0) {
          item.flagEnabled = true;
        }
      });
  }

  hasMemo(item: MenuItem) {
    this.queryService
      .getUserRolesForFiling(this.filing.filingId)
      .subscribe((roles) => {
        roles.forEach((role) => {
          const type = /OGE_.*/.test(role.surrogateKey) ? 'oge' : 'agency';
          if (
            this.filing.memo &&
            this.filing.memo[type] &&
            this.filing.memo[type].text
          ) {
            item.flagEnabled = true;
          }
        });
        const isFiler =
          this.sessionService.getMaxUsername() === this.filing.userId;
        if (isFiler) {
          item.isEnabled = false;
        }
      });
  }

  checkCompareIsAvailable(item: MenuItem) {
    if (!this.filing.allowCompare) {
      return;
    }

    item.flagEnabled = false;
    const keys = Object.keys(this.flags);
    keys.forEach((flag) => {
      item.flagEnabled = item.flagEnabled || this.flags[flag];
    });

    item.items.forEach((subItem) => {
      subItem.flagEnabled = this.flags[subItem.compareTag];
    });
  }

  callAction(item: MenuItem, action: string) {
    switch (action) {
      case 'downloadComparePriorReport':
        this.reportUtilitiesService.downloadComparePriorReport(this.filing, this.downloadComparePriorReportFormElement);
        break;
      case 'learnAboutIntegrity':
        const doc = this.menuItemsService.userResourcesDocument;
        if (!!doc) {
          this.menuItemsService.download(doc);
        }
        break;
      case 'publicFinancialDisclosureGuide':
        window.open('https://www.oge.gov/Web/278eGuide.nsf', '_blank');
        break;

      default:
        console.log('--> Unhandled action ' + action);
    }
  }

  closeWindow(): void {
    this.router.navigate(['reviewer/close-window']);
  }
}
